import useUser from '@/hooks/useUser';
import { ICaseTableFilters } from '@/types/ICaseTableFilters';
import { ISelectOption } from '@/types/ISelectOption';
import { ITableFilterOptions } from '@/types/ITableFilterOptions';
import { createSelectOption } from '@/utils/misc';
import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { SearchableSelect } from '../controls/SearchableSelect';

interface IFieldFiltersProps {
  handleFilterChange: (field: string, selectedOption: ISelectOption) => void;
  filterOptions: ITableFilterOptions;
  filters: ICaseTableFilters | null;
}
const TableFilters: FC<IFieldFiltersProps> = (props) => {
  const { handleFilterChange, filterOptions, filters } = props;
  const user = useUser();

  useEffect(() => {
    if (user.initials && !user.isSecretary()) {
      handleFilterChange('doctor_initials', createSelectOption(user.initials || '-'));
    }
  }, [user.initials]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <SearchableSelect
        placeholder="Lokation"
        options={
          filterOptions.location.length ? filterOptions.location.map(createSelectOption) : []
        }
        onChange={(selectedOption: ISelectOption | null) =>
          handleFilterChange('location', selectedOption)
        }
        sx={{
          minWidth: '140px',
        }}
        defaultValue={filters?.location}
        multiple={true}
      />
      <SearchableSelect
        placeholder="Speciale"
        options={
          filterOptions.specialty.length > 0 ? filterOptions.specialty.map(createSelectOption) : []
        }
        onChange={(selectedOption: ISelectOption | null) =>
          handleFilterChange('specialty', selectedOption)
        }
        sx={{
          minWidth: '140px',
        }}
        defaultValue={filters?.specialty}
        multiple={true}
      />
      <SearchableSelect
        placeholder="Konsultationstype"
        options={
          filterOptions.consultation_type.length > 0
            ? filterOptions.consultation_type.map(createSelectOption)
            : []
        }
        onChange={(selectedOption: ISelectOption | null) => {
          handleFilterChange('consultation_type', selectedOption);
        }}
        defaultValue={filters?.consultation_type}
        sx={{
          minWidth: '140px',
        }}
        multiple={true}
      />
      <SearchableSelect
        placeholder="Læge"
        options={
          filterOptions.doctor_initials.length > 0
            ? filterOptions.doctor_initials.map(createSelectOption)
            : []
        }
        onChange={(selectedOption: ISelectOption | null) => {
          handleFilterChange('doctor_initials', selectedOption);
        }}
        sx={{
          minWidth: '140px',
        }}
        defaultValue={filters?.doctor_initials}
        multiple={true}
      />
    </Box>
  );
};

export default TableFilters;
