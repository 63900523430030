import { DialogContentText } from '@mui/material';
import { observer } from 'mobx-react';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  open: boolean;
  onAccept: () => void;
}
export const NoDiagnosisWarning = observer(({ open, onAccept }: IProps) => {
  return (
    <ConfirmDialog
      title="No diagnosis selected"
      open={open}
      onAccept={onAccept}
      acceptActionLabel="Go back"
    >
      <DialogContentText>
        Du har ikke valgt en primær diagnose, gå venligst tilbage og vælg en fra listen eller brug
        søgefeltet. Tak 🙏
      </DialogContentText>
    </ConfirmDialog>
  );
});
