import { DEFAULT_CUSTOMER, KEYCLOAK_URL } from '@/config/constants';
import Keycloak from 'keycloak-js';
import {
  trackComingBackOnline,
  trackTokenUpdatedAfterComingBackOnline,
  trackTokenUpdatedOnReceivingNewCase,
} from '../analytics';
import { getCustomerName } from '@/utils/misc';

const INTERNET_CONNECTION_CHECK_INTERVAL_MS = 1000; // 1 second.
const TOKEN_MIN_VALIDITY_S = 2 * 60 * 60; // 2 hours: min time remaining until the token expires.

export const getKeycloak = () => {
  // let customer = new URLSearchParams(window.location.search).get('customer');
  let customer = getCustomerName();
  if (customer == null) {
    customer = DEFAULT_CUSTOMER;
  }

  const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    realm: customer,
    clientId: 'frontend',
  });

  return keycloak;
};

export const authUpdateWhenComeBackOnline = (keycloak: Keycloak) => {
  console.log('Come back online');
  trackComingBackOnline();

  // Checking the Internet connection to restore once a second.
  const interval = setInterval(() => {
    if (!navigator.onLine) {
      console.log('Waiting for the Internet connection to restore');
      return;
    }

    keycloak
      .updateToken(TOKEN_MIN_VALIDITY_S)
      .then((refreshed) => {
        clearInterval(interval);

        if (refreshed) {
          console.log('Token has been successfully refreshed');
          trackTokenUpdatedAfterComingBackOnline();
        } else {
          console.log('Token is still valid');
        }
      })
      .catch(() => {
        clearInterval(interval);

        console.error(
          'Failed to refresh the token after coming back online. Re-authenticating the user...',
        );
        keycloak
          .login()
          .then(() => console.log('Redirected to the login form'))
          .catch(() => console.error('Could not redirect to the login form'));
      });
  }, INTERNET_CONNECTION_CHECK_INTERVAL_MS);
};

export const authUpdateOnReceivingNewCase = (keycloak: Keycloak) => {
  return keycloak
    .updateToken(TOKEN_MIN_VALIDITY_S)
    .then((refreshed) => {
      if (refreshed) {
        console.log('Token has been successfully refreshed');
        trackTokenUpdatedOnReceivingNewCase();
      } else {
        console.log('Token is still valid');
      }
      return Promise.resolve(true);
    })
    .catch(() => {
      console.error(
        'Failed to refresh the token on receiving a new case. Re-authenticating the user...',
      );
      keycloak
        .login()
        .then(() => console.log('Redirected to the login form'))
        .catch(() => console.error('Could not redirect to the login form'));
    });
};
