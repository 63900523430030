import { flexColumnFullHeight } from '@/utils/theme';
import { Theme } from '@emotion/react';
import { Box, Paper, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  sx?: SxProps<Theme>;
  wrapperSx?: SxProps<Theme>;
}
export const ContentWrapper: FC<PropsWithChildren<IProps>> = ({
  children,
  sx = {},
  wrapperSx = {},
}) => {
  return (
    <Box sx={{ p: 2, ...flexColumnFullHeight, ...(wrapperSx as any) }}>
      <Paper sx={{ ...sx }}>{children}</Paper>
    </Box>
  );
};
