import { Box, Divider } from '@mui/material';
import { BottomMenu } from './BottomMenu';
import { SelectedCodes } from './SelectedCodes';
import { AlternativeCodes } from '@/components/case/AlternativeCodes';

export const CodeSelection = ({ editMode }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        gridTemplateColumns: editMode ? '1fr 1px 1fr' : '1fr',
        height: '100%',
      }}
    >
      <SelectedCodes />

      {editMode && (
        <>
          <Divider
            sx={{
              background: 'rgba(0, 0, 0, 0.12)',
            }}
          />
          <AlternativeCodes />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          gridColumn: '1 / 4',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <BottomMenu />
      </Box>
    </Box>
  );
};
