import { PATH_INBOX } from '@/config/paths';
import { EOrder } from '@/types/EOrder';
import { ICaseTableFiltersExtended } from '@/types/ICaseTableFiltersExtended';
import { ICaseTableOrder } from '@/types/ICaseTableOrder';
import { makeAutoObservable } from 'mobx';

export class UserPreferenceStore {
  public lastInboxPath: string = PATH_INBOX;
  public showDrafts: boolean = false;

  public flaggedCasesFilters: ICaseTableFiltersExtended;
  public flaggedCasesOrder: ICaseTableOrder;

  public completedCasesFilters: ICaseTableFiltersExtended;
  public completedCasesOrder: ICaseTableOrder;

  constructor() {
    makeAutoObservable(this);
    this.flaggedCasesOrder = {
      order: EOrder.ASC,
      orderBy: 'created_at',
    };
  }

  public setLastInboxPath = (path: string) => {
    this.lastInboxPath = path;
  };

  public setShowDrafts = (showDrafts: boolean) => {
    this.showDrafts = showDrafts;
  };

  public setFlaggedCasesFilters = (filters: ICaseTableFiltersExtended) => {
    this.flaggedCasesFilters = this.mergeFilters(this.flaggedCasesFilters, filters);
  };

  public setFlaggedCasesOrder = (orderBy: string, order: EOrder) => {
    this.flaggedCasesOrder = {
      order,
      orderBy,
    };
  };

  public setCompletedCasesFilters = (filters: ICaseTableFiltersExtended) => {
    this.completedCasesFilters = this.mergeFilters(this.completedCasesFilters, filters);
  };

  public setCompletedCasesOrder = (orderBy: string, order: EOrder) => {
    this.completedCasesOrder = {
      order,
      orderBy,
    };
  };

  private mergeFilters = (
    oldFilters: ICaseTableFiltersExtended,
    newFilters: ICaseTableFiltersExtended,
  ) => {
    const mergedFilters = { ...oldFilters };

    Object.keys(newFilters).forEach((key: string) => {
      mergedFilters[key] = newFilters[key];
    });

    return mergedFilters;
  };
}
