import { Box, Button, CircularProgress } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  loading: boolean;
  disabled: boolean;
  [key: string]: any;
}
export const LoadingButton: FC<PropsWithChildren<IProps>> = ({
  loading,
  disabled,
  children,
  ...rest
}) => {
  return (
    <Button disabled={disabled || loading} {...rest}>
      <Box>{children}</Box>
      {loading && <CircularProgress color="inherit" size={16} />}
    </Button>
  );
};
