import { ICaseTableFiltersExtended } from '@/types/ICaseTableFiltersExtended';
import { ISelectOption } from '@/types/ISelectOption';
import { ITableFilterOptions } from '@/types/ITableFilterOptions';
import { Box, Chip, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import DebouncedSearchBar from '../controls/DebouncedSearchBar';
import DateRangeFilter from './DateRangeFilter';
import TableFilters from './TableFilters';

interface IProps {
  colSpan: number;
  handleSearch: (searchPhrase: string) => void;
  handleFilterChange: (field: string, selectedOption: ISelectOption | ISelectOption[]) => void;
  handleDateFilterChange: (filterStartDate: Date | null, filterEndDate: Date | null) => void;
  filterOptions: ITableFilterOptions;
  filterValues: ICaseTableFiltersExtended | null;
  rowCount?: number;
}
const TableHeadFilters: FC<IProps> = (props) => {
  const {
    colSpan,
    handleSearch,
    handleFilterChange,
    handleDateFilterChange,
    filterOptions,
    filterValues,
    rowCount,
  } = props;

  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ px: 0, pt: 0.5, pb: 0, border: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
            pb: 3,
            borderBottom: '1px solid #ddd',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexGrow: 1,
              gap: 2,
            }}
          >
            <TableFilters
              handleFilterChange={handleFilterChange}
              filterOptions={filterOptions}
              filters={filterValues}
            />
            <DebouncedSearchBar
              defaultValue={filterValues?.query}
              sx={{ minWidth: '170px', maxWidth: '250px' }}
              onSearch={handleSearch}
              placeholder="Bemærkninger, Patient ID"
            />
            {rowCount > 0 && <Chip label={`${rowCount} sager`} />}
          </Box>
          <DateRangeFilter
            defaultStartDate={filterValues?.from_date || null}
            defaultEndDate={filterValues?.to_date || null}
            onChange={handleDateFilterChange}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableHeadFilters;
