import { ROLE_SECRETARY, ROLE_ADMIN, ROLE_DOCTOR } from '@/config/constants';
import { IUser } from '@/types/IUser';
import { getDoctorInitialsFromEmail } from '@/utils/misc';
import { makeAutoObservable } from 'mobx';

export class UserStore {
  name: string;
  username: string;
  email: string;
  initials: string;
  roles: string[] = [];
  loaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  // @todo: Describe the data structure through an interface.
  public setUserData = (user: IUser) => {
    this.name = user.name;
    this.username = user.preferred_username;
    this.email = user.email;
    this.initials = getDoctorInitialsFromEmail(user.email);
    this.roles = user.roles || [];
    this.loaded = true;
  };

  public hasRole = (role: string): boolean => {
    return this.roles.includes(role);
  };

  public getPrimaryRole = (): string => {
    if (this.hasRole(ROLE_ADMIN)) {
      return ROLE_ADMIN;
    } else if (this.hasRole(ROLE_SECRETARY)) {
      return ROLE_SECRETARY;
    }
    return ROLE_DOCTOR;
  };

  /**
   * Simplified to only support a single role, Secretary.
   * secretary can:
   * - use pedals
   * - see unfiltered view
   * - submit cases
   * - see a different welcome message
   */
  public isSecretary = (): boolean => {
    return this.hasRole(ROLE_SECRETARY);
  };
}
