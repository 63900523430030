import useCases from '@/hooks/useCases';
import { DialogContentText } from '@mui/material';
import { observer } from 'mobx-react';
import ConfirmDialog from './ConfirmDialog';
import DuplicateCodesWarning from './DuplicateCodesWarning';
import { EAudioProcessingStep } from '@/types/audio/EAudioProcessingStep';
import { FeedbackGetter } from './FeedbackGetter';
import { useState } from 'react';

interface ISubmitDialogProps {
  open: boolean;
  handleClose: () => void;
  hasDuplicateCodes?: boolean;
  handleSubmit: () => void;
}

export const SubmitDialog = observer(
  ({ open, handleClose, hasDuplicateCodes = false, handleSubmit }: ISubmitDialogProps) => {
    const cases = useCases();
    const [askForFeedback, _setAskForFeedback] = useState(Math.random() < 0.25);

    // ask for feedback in 25% of cases

    return (
      <ConfirmDialog
        title="Næsten færdig!"
        open={open}
        onCancel={handleClose}
        onAccept={handleSubmit}
        isLoading={
          cases.fullAudioProcessingStep === EAudioProcessingStep.STORING ||
          cases.segmentAudioProcessingStep === EAudioProcessingStep.STORING
        }
      >
        <DialogContentText>
          Denne handling vil indsende sagen til {cases.config?.ehr_name}. Er du sikker på, at du vil
          fortsætte?
          {hasDuplicateCodes && <DuplicateCodesWarning />}
        </DialogContentText>
        {askForFeedback && <FeedbackGetter caseId={cases.caseId} />}
      </ConfirmDialog>
    );
  },
);
