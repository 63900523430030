import {
  Autocomplete,
  Box,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  Paper,
  Button,
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { SyntheticEvent, useEffect, useState } from 'react';

import { blue, grey } from '@mui/material/colors';
import { TemplateOption, getTemplateText, listTemplates } from './TemplateHandler';

export const TemplateSearchBar = ({ handleInsertTemplate }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const selectedValue = null;
  const [options, setOptions] = useState<TemplateOption[]>([]);

  const handleSearch = (e: SyntheticEvent<Element, Event>, newInputValue: string) => {
    setInputValue(newInputValue);
    setOptions(listTemplates(newInputValue));
  };

  const handleClear = () => {
    setInputValue('');
    setOptions(listTemplates(''));
  };

  const handleOnChange = (
    e: SyntheticEvent<Element, Event>,
    newSelectedTemplateOption: TemplateOption,
  ) => {
    const templateText = getTemplateText(newSelectedTemplateOption.id);

    handleInsertTemplate(templateText);
    handleClear();
  };

  useEffect(() => {
    setOptions(listTemplates(''));
  }, []);

  const isOptionEqualToValue = (option: TemplateOption, value: TemplateOption) =>
    option.id === value.id;

  return (
    <Autocomplete
      noOptionsText="No results"
      size="small"
      disablePortal
      autoHighlight
      filterOptions={(x) => x}
      onInputChange={handleSearch}
      onChange={handleOnChange}
      onBlur={handleClear}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
      getOptionLabel={(option: TemplateOption) => option.title}
      inputValue={inputValue}
      value={selectedValue}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: grey[50],
            minWidth: 260,
            width: '60%',
            borderBottom: '1px solid #fafafa',
            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              padding: 0,
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 0.5, mr: 0 }}>
                <PostAddIcon color="primary" fontSize="small" />
              </InputAdornment>
            ),
          }}
          placeholder="Indsæt skabelon"
        />
      )}
      PaperComponent={CustomPaper}
      renderOption={(props, option) => (
        <ListItem {...props} data-dd-action-name="Select template" key={option.id}>
          <ListItemText
            primary={option.title}
            secondary={option.description}
            primaryTypographyProps={{
              fontWeight: 600,
            }}
          />
        </ListItem>
      )}
    />
  );
};

function CustomPaper({ children }) {
  return (
    <Paper
      className="searchBarPaper"
      sx={{
        minWidth: 250,
        marginTop: 1,
        padding: 0.5,
        backgroundColor: 'rgba(255, 255, 255, 0.88)',
        backdropFilter: 'blur(4px)',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.08)',
        border: '1px solid #eaeaea',
        '& .MuiAutocomplete-listbox': {
          padding: 0.5,
        },
        '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
          bgcolor: blue[50],
          borderRadius: 2,
        },
      }}
    >
      {children}
    </Paper>
  );
}
