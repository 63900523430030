export const DEFAULT_DEVICE_NAME = 'Default';

/**
 * Connect to the recorder stream in order to report the voice volume level to UI.
 *
 * @param recorder
 * @param setVolumeLevel
 * @returns {void}
 */
export const analyzeRecording = (
  recorder: MediaRecorder,
  setVolumeLevel: (volumeLevel: number) => void,
) => {
  if (recorder == null || recorder.stream == null) {
    return;
  }

  const context = new AudioContext();
  const analyzer = context.createAnalyser();
  // Connect a media stream source to connect to the analyser.
  const source = context.createMediaStreamSource(recorder.stream);
  // Create a Uint8Array based on the frequencyBinCount(fftSize / 2).
  const dataArray = new Uint8Array(analyzer.frequencyBinCount);
  // Connect the analyser.
  source.connect(analyzer);

  /**
   * Report progress on each animation frame until the recording is stopped or paused.
   */
  const reportProgress = () => {
    analyzer.getByteFrequencyData(dataArray);
    const volume = Math.floor((Math.max(...dataArray) / 255) * 100);
    setVolumeLevel(volume);

    if (recorder && recorder.state !== 'paused') {
      requestAnimationFrame(reportProgress);
    } else {
      context.close();
      setVolumeLevel(0);
    }
  };

  // Start reporting.
  reportProgress();
};

/**
 * Connect to the audio stream in order to report the voice volume level to UI.
 *
 * @param stream
 * @param setVolumeLevel
 * @returns {void}
 */
export const analyzeAudioStream = (
  stream: MediaStream,
  setVolumeLevel: (volumeLevel: number) => void,
) => {
  if (stream == null) {
    return;
  }

  const context = new AudioContext();
  const analyzer = context.createAnalyser();
  // Connect a media stream source to connect to the analyser.
  const source = context.createMediaStreamSource(stream);
  // Create a Uint8Array based on the frequencyBinCount(fftSize / 2).
  const dataArray = new Uint8Array(analyzer.frequencyBinCount);
  // Connect the analyser.
  source.connect(analyzer);

  /**
   * Report progress on each animation frame until the recording is stopped or paused.
   */
  const reportProgress = () => {
    analyzer.getByteFrequencyData(dataArray);
    const volume = Math.floor((Math.max(...dataArray) / 255) * 100);
    setVolumeLevel(volume);

    if (stream && stream.active) {
      requestAnimationFrame(reportProgress);
    } else {
      context.close();
      setVolumeLevel(0);
    }
  };

  // Start reporting.
  reportProgress();
};

export const getAudioDevices = async (): Promise<MediaDeviceInfo[]> => {
  let devices = (await navigator.mediaDevices?.enumerateDevices?.()) || [];
  devices = devices.filter((device) => device.kind === 'audioinput' && device.label !== '');
  if (devices.length > 0) {
    return Promise.resolve(devices);
  }

  return Promise.reject('No audio input devices allowed.');
};

export const reportVolumeLevel = async (
  setVolumeLevel: (volumeLevel: number) => void,
  deviceId?: string,
): Promise<MediaStream | null> => {
  return navigator.mediaDevices
    .getUserMedia({
      audio: deviceId == null ? true : { deviceId: deviceId },
    })
    .then((stream) => {
      analyzeAudioStream(stream, setVolumeLevel);
      return stream;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const getSelectedDevice = (
  devices: MediaDeviceInfo[],
  currentSessionDevice?: MediaDeviceInfo,
): MediaDeviceInfo => {
  if (currentSessionDevice) {
    return currentSessionDevice;
  }
  const jabraDevice = devices.find(
    (device) =>
      device.label.toLowerCase().includes('jabra') &&
      !device.label.toLowerCase().includes('communication'),
  );
  if (jabraDevice) {
    return jabraDevice;
  }

  // alert('No Jabra device found, using default device');
  // else return the 'default' device
  const defaultDevice = devices.find((device) => device.label.includes(DEFAULT_DEVICE_NAME));

  if (defaultDevice) {
    return defaultDevice;
  }
  return devices[0];
};

export const getMicrophoneSampleRate = async (): Promise<number> => {
  const defaultValue = 48000;
  // This is obviously stupid, but the same rate isn't that important
  return defaultValue;
};
