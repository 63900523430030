import { cloudCards, noteFieldTextStyles } from '@/utils/theme';

// Define style objects outside the component to reduce LOC
export const containerStyle = {
  display: 'grid',
  gridTemplateRows: 'auto 84px',
  paddingTop: 16,
  paddingLeft: 0,
  paddingRight: 16,
  height: '100%',
  width: '100%',
  maxWidth: '1400px',
  margin: 'auto',
};

export const editorContainerStyle = {
  display: 'grid',
  overflowY: 'overlay' as 'scroll', // DO NOT JUDGE ME
  width: '100%',
  height: '100%',
  whiteSpace: 'pre-wrap',
  border: '1px solid #dadada',
  borderRadius: 12,
  ...noteFieldTextStyles,
  ...cloudCards,
};

export const bottomDivStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  alignItems: 'center',
  gap: 12,
  padding: 8,
  marginTop: 2,
};
