import { FC, useEffect } from 'react';
import { unstable_Blocker as Blocker, unstable_useBlocker as useBlocker } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  blocker: Blocker | null;
  message: string;
  onAccept?: (next: () => void) => void;
}
const ConfirmUnsavedChangesDialog: FC<IProps> = ({ blocker, message, onAccept }) => {
  if (blocker && blocker.state !== 'blocked') {
    return <></>;
  }

  return (
    <ConfirmDialog
      title={message}
      open={blocker.state === 'blocked'}
      onCancel={() => blocker.reset?.()}
      onAccept={() => {
        // If Accept handler is set, run it first and only then allow
        // going to another page as requested by the user.
        if (onAccept) {
          onAccept(() => {
            if (blocker.state === 'blocked') {
              blocker.proceed?.();
            }
          });
          return;
        }

        // If the Accept handler is not set, go leave the page right away as requested by the user.
        if (blocker.state === 'blocked') blocker.proceed?.();
      }}
    />
  );
};

export default ConfirmUnsavedChangesDialog;

export function useConfirmUnsavedChanges(isDirty: boolean) {
  const blocker = useBlocker(isDirty);

  // Reset the blocker if the form is not dirty.
  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  return { blocker };
}
