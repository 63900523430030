import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';

export interface CodeSystemModelType {
  id?: number;
  name: string;
  updatedAt: number;
  codes: ITerminologyCodeRead[];
}

export const CodeSystemTables = {
  codeSystems: '++id, updatedAt, name', // Primary key and indexed props
};
