import templateDataWeirdFormat from './StaticTemplateData.json' assert { type: 'json' };
import fuzzysort from 'fuzzysort';

const templateData = convertJson(templateDataWeirdFormat);

export interface Template {
  template_id: number;
  type: number;
  title: string;
  text: string;
  text_format_version: number;
  code: string;
  description: string;
}

interface InputJson {
  template_id: { [key: string]: number };
  doctor_id: { [key: string]: number };
  type: { [key: string]: number };
  title: { [key: string]: string };
  text: { [key: string]: string };
  text_format_version: { [key: string]: number };
  created: { [key: string]: number };
  template_sort: { [key: string]: number };
  template_flags: { [key: string]: number };
  code: { [key: string]: string | null };
  description: { [key: string]: string };
}

export interface TemplateOption {
  id: number;
  title: string;
  description: string;
}

const getDefaultTemplateOptions = () => {
  const templatePreferences = JSON.parse(localStorage.getItem('templatePreferences') || '{}') as {
    [key: number]: number;
  };
  let sortedTemplateIDs = Object.entries(templatePreferences).sort((a, b) => b[1] - a[1]) || [];

  // for each templateID, return the full template object. If the template is not found, skip
  const topTemplates = [];
  // template id if the first element of the tuple
  for (const [templateID, _] of sortedTemplateIDs) {
    const template = templateData.find((template) => template.template_id === Number(templateID));
    if (template) {
      topTemplates.push({
        id: template.template_id,
        title: template.title,
        description: template.description,
      });
    }
  }

  // if the top templates are less than 10, add the first 10-n templates to the list
  if (topTemplates.length < 10) {
    const remainingTemplates = templateData
      .filter((template) => !topTemplates.some((top) => top.id === template.template_id))
      .slice(0, 20 - topTemplates.length)
      .map((template) => {
        return {
          id: template.template_id,
          title: template.title,
          description: template.description,
        };
      });

    topTemplates.push(...remainingTemplates);
  }

  return topTemplates;
};

const setTemplatePreference = (templateID: number) => {
  const templatePreferences = JSON.parse(localStorage.getItem('templatePreferences') || '{}') as {
    [key: number]: number;
  };

  templatePreferences[templateID] = (templatePreferences[templateID] || 0) + 1;

  localStorage.setItem('templatePreferences', JSON.stringify(templatePreferences));
};

export const listTemplates = (query: string): TemplateOption[] => {
  // filter all templateData

  if (!query) {
    return getDefaultTemplateOptions();
  }

  const results = fuzzysort.go(query, templateData, {
    keys: ['title', 'description'],
    threshold: -1000,
    limit: 10,
  });

  return results.map((result) => {
    return {
      id: result.obj.template_id,
      title: result.obj.title,
      description: result.obj.description,
    };
  });
};

export const getTemplateText = (templateId: number) => {
  // return the text of the template with the given ID
  setTemplatePreference(templateId);

  const template = templateData.find((template) => template.template_id === templateId);

  // if text_format_version is 1, convert <b> to ** and <i> to * in the text
  if (template.text_format_version === 1) {
    template.text = template.text
      .replace(/<b>/g, '**')
      .replace(/<\/b>/g, '**')
      .replace(/<i>/g, '*')
      .replace(/<\/i>/g, '*');
  }

  // Properly indent the text
  template.text = template.text.replace(/\n\t/g, '\n&#x20;\t');
  template.text = template.text.replace(/^\t/g, '&#x20;\t');

  return template.text;
};

// this only runs once when the file is imported, and takes approx 1ms
function convertJson(inputJson: InputJson): Template[] {
  // Step 1: Convert the input object structure into an array of objects
  const entries = Object.keys(inputJson.template_id).map((index) => {
    return {
      template_id: inputJson.template_id[index],
      title: inputJson.title[index],
      description: inputJson.description[index],
      text: inputJson.text[index],
      text_format_version: inputJson.text_format_version[index],
      code: inputJson.code[index] || '',
      type: inputJson.type[index],
    };
  });

  // Step 2: Filter out entries where type !== 1
  return entries.filter((template) => template.type === 1 && template.text.length > 0);
}
