import routes from '@/config/routes';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter(routes);

// To prevent possible issues with hot reloading and react router.
if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default router;
