import { FC, MouseEvent, useEffect, useState } from 'react';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  title: string;
  cancelActionLabel?: string;
  acceptActionLabel?: string;
  open: boolean;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  onAccept: (e: MouseEvent<HTMLButtonElement>) => void;
}
const EditProtectionDialog: FC<IProps> = (props) => {
  const { open, onCancel, onAccept, title, cancelActionLabel, acceptActionLabel } = props;
  return (
    <ConfirmDialog
      title={title}
      cancelActionLabel={cancelActionLabel}
      acceptActionLabel={acceptActionLabel}
      open={open}
      onCancel={onCancel}
      onAccept={onAccept}
    />
  );
};

export default EditProtectionDialog;

export function useEditProtection(isFlagged: boolean) {
  const [readonly, setReadonly] = useState<boolean>(isFlagged);
  const [readonlyDialogOpen, setReadonlyDialogOpen] = useState<boolean>(false);

  const handleReadonlyElementClick = (e: MouseEvent<HTMLElement>) => {
    if (!readonly) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    setReadonlyDialogOpen(true);
  };
  const handleCancelClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setReadonlyDialogOpen(false);
  };
  const handleAcceptClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setReadonly(false);
    setReadonlyDialogOpen(false);
  };

  useEffect(() => {
    setReadonly(isFlagged);
  }, [isFlagged]);

  return {
    handleReadonlyElementClick,
    handleAcceptClick,
    handleCancelClick,
    readonly,
    readonlyDialogOpen,
  };
}
