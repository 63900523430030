import { useState } from 'react';

import { ITerminologyCodeRead, IPreparedCode } from '@/types/ITerminologyCodeRead';
import { codeSearched, codeSearchResultAdded } from '@/services/analytics';
import { indexCode } from '@/utils/codes';
import useCases from './useCases';
import useSearchCodes from './useSearchCodes';

const useSearchBarClient = (codeSystems: ITerminologyCodeRead['code_system'][]) => {
  const { options, loading, search } = useSearchCodes(codeSystems);

  const cases = useCases();
  const [searchValue, setSearchValue] = useState<ITerminologyCodeRead['name']>('');

  const searchHandler = (query: ITerminologyCodeRead['name']) => {
    search(query);
    setSearchValue(query);
    codeSearched(cases.caseId, query);
  };

  const codeSelectHandler = (newInputValue: IPreparedCode) => {
    let newCode = indexCode({ new_modifiers: [], ...newInputValue.obj });
    // if the code is already selected, do not add it again
    if (cases.selectedCodes.some((code) => code.name === newCode.name)) {
      return;
    }
    cases.addSelectedCode(newCode);
    codeSearchResultAdded(cases.caseId, searchValue, newCode);
  };

  const selectedCodes = cases.selectedCodes.map((code) => code.name);

  return { options, loading, searchHandler, codeSelectHandler, selectedCodes };
};

export default useSearchBarClient;
