import formatText from '@/utils/transcriptReplacements';

export const fixDictation = (originalDictation: string) => {
  let dictation = originalDictation;

  // Punctuation change patterns
  const punctuationCorrections: Array<[RegExp, string]> = [
    [/[\W]{0,2}punktum[\W]?[ ]*/giu, '. '],
    [/[\W]{0,1}ny[e]? linje[\W]{0,2}/giu, '\n'],
    [/[\W]{0,1}ny[e]? afsnit[\W]{0,2}/giu, '\n\n'],
    [/[\W]{0,1}ny[t]? afsnit[\W]{0,2}/giu, '\n\n'],
    [/[\W]{0,1}naeste linje[\W]{0,2}/giu, '\n'],
    [/[\W]{0,2}skråstreg[\W]?/giu, '/'],
    [/[\W]{0,2}kolon[\W]?/giu, ': '],
    [/[\W]{0,2}komma[\W]?/giu, ', '],
  ];

  // Apply the punctuation corrections
  for (const [pattern, replacement] of punctuationCorrections) {
    dictation = dictation.replace(pattern, replacement);
  }

  // Unless the dictation ends with a whitespace, add a space at the end
  if (!/\s$/.test(dictation)) {
    dictation += '&#x20;';
  }

  // Hackly short term fix to prevent weird mis-transcriptions
  // replace the acronym AFSL with ACL
  dictation = dictation.replace(/AFSL/g, 'ACL');

  dictation = formatText(dictation);

  return dictation;
};
