import { MODE } from '@/config/constants';

export const isDevelopment = () => {
  return MODE === 'development';
};

export const isTest = () => {
  return MODE === 'test';
};

export const isStaging = () => {
  return window.location.hostname.search('staging') !== -1;
};
