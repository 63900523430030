import { useState, useEffect } from 'react';

import { getCodes } from '@/services/BackendAPI';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';

import useAlerts from './useAlerts';

import { getCodeSystems } from '@/services/databaseActions';
import { storeCodes, deleteOldCodes } from '@/services/databaseActions/codeSystem';

const CODES_CACHING_THRESHOLD = 2 * 7 * 24 * 60 * 60 * 1000; // 2 weeks cache

type ErrorType = {
  codeSystem: ITerminologyCodeRead['code_system'];
  message: string;
};

const filterCodes = (code: ITerminologyCodeRead) => {
  // TODO: this filtering needs to be moved to backend side
  // Remove modifier codes
  if (code.type === 'modifier') return false;
  // Remove all top level codes (don't have digits)
  return /\d/.test(code.name);
};

const useFetchAllCodes = (codeSystems: ITerminologyCodeRead['code_system'][]) => {
  const alerts = useAlerts();
  const [codes, setCodes] = useState<ITerminologyCodeRead[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const fetchCodeSystemCodes = async (codeSystem) => {
    try {
      const codes = await getCodes({ code_system: codeSystem });
      return codes.filter(filterCodes);
    } catch (error) {
      alerts.error('Error message');
    }
  };

  useEffect(() => {
    const fetchCodes = async () => {
      if (!codeSystems || codeSystems.length === 0) {
        return;
      }
      // Try to get the codes from the DB first
      const codeSystemsInDb = await getCodeSystems(codeSystems);
      const fetchAndUpdateCodes = async (codeSystem) => {
        const latestCodesInDB = codeSystemsInDb[codeSystem];
        if (latestCodesInDB) setCodes(latestCodesInDB.codes);

        const isUpdatedRecently =
          latestCodesInDB &&
          new Date().getTime() - latestCodesInDB.updatedAt < CODES_CACHING_THRESHOLD;

        if (!isUpdatedRecently) {
          try {
            setLoading(true);
            const codes = await fetchCodeSystemCodes(codeSystem);
            storeCodes(codeSystem, codes);
            deleteOldCodes(codeSystem, CODES_CACHING_THRESHOLD);
            setCodes(codes);
          } catch (err) {
            setErrors((prevErrors) => [
              ...prevErrors,
              {
                codeSystem,
                message: err,
              },
            ]);
          } finally {
            setLoading(false);
          }
        }
      };
      // Try to fetch and update the codes in db for all code systems, asynchronously
      await Promise.all(codeSystems.map(fetchAndUpdateCodes));
    };

    fetchCodes();
  }, [codeSystems]);

  return { codes, loading, errors };
};

export default useFetchAllCodes;
