import { CODE_CATEGORIES } from '@/config/constants';
import useCases from '@/hooks/useCases';
import { ICode } from '@/types/ICode';
import { getNewModifiers } from '@/utils/misc';
import { flexColumnFullHeight } from '@/utils/theme';
import { Box, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { CardContainer } from '../layout/CardContainer';
import { CodeCard } from './CodeCard';
import CodeTypeHeader from './CodeTypeHeader';
import { SearchBar } from './SearchBar';

export const AlternativeCodes = observer(() => {
  const cases = useCases();

  const addHandler = (code: ICode) => {
    cases.addSelectedCode(code);
  };

  const categories = CODE_CATEGORIES;

  return (
    <CardContainer
      title="Foreslåede koder"
      sx={{
        ...flexColumnFullHeight,
        height: '100%',
      }}
    >
      <SearchBar codeSystems={cases?.config?.code_systems} />
      <Box
        sx={{
          mt: 1,
          pr: 2,
          mr: -1,
          pb: 2,
          overflowY: 'auto',
        }}
      >
        {categories.map((category) => (
          <Box
            key={category}
            sx={{
              mb: 2,
              '&:nth-last-of-type(1)': {
                mb: 0,
              },
            }}
          >
            <CodeTypeHeader>{category}</CodeTypeHeader>
            <Stack spacing={1}>
              {cases
                .getSuggestedCodes(category)
                .slice(0, 4)
                // filter to check if the code is already selected
                .filter((code) => {
                  return !cases.selectedCodes.find(
                    (selectedCode) => selectedCode.name === code.name,
                  );
                })
                .map((code) => (
                  <CodeCard
                    key={
                      code.name +
                      getNewModifiers(code)
                        .map((modifier) => modifier.name)
                        .join()
                    }
                    code={code}
                    edit={cases.codesEditMode}
                    addHandler={() => addHandler(code)}
                  />
                ))}
            </Stack>
          </Box>
        ))}
      </Box>
    </CardContainer>
  );
});
