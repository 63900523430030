import { Box } from '@mui/material';
import '../assets/styles/app-loading.css';

/**
 * AuthProgressIndicator component mimics Electron's preload progress indicator
 * to make them work together one by one and make the app loading experience smooth.
 *
 * See also code-electron/preload/index.ts useLoading()
 */
const AuthProgressIndicator = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#282c34',
      zIndex: 9,
    }}
  >
    <Box
      sx={{
        animationFillMode: 'both',
        width: '50px',
        height: '50px',
        background: '#fff',
        animation: 'square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite',
      }}
    />
  </Box>
);

export default AuthProgressIndicator;
