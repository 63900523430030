import { datadogRum } from '@datadog/browser-rum';

import { APP_VERSION, DEPLOYMENT_ENV } from '@/config/constants';
import { checkPrerequisites } from './analytics';

export function initDatadogRUM() {
  const enabled = checkPrerequisites();

  if (!enabled) {
    console.log('Datadog RUM disabled in development');
    return;
  }

  datadogRum.init({
    applicationId: '92da3c53-3e0f-4985-aae2-9607a3a57f1c',
    clientToken: 'pubf4ee452e10e37c57a5e75b312e872fd5',
    site: 'datadoghq.eu',
    service: 'code-rum',
    env: DEPLOYMENT_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.startSessionReplayRecording();
}
