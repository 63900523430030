import { cardContainerStyles } from '@/utils/theme';
import { Box, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react';

interface CardContainerProps {
  children?: React.ReactNode;
  menu?: React.ReactNode;
  title?: string;
  sx?: object;
}

export const CardContainer = observer(({ children, menu, title, sx }: CardContainerProps) => {
  return (
    <Box
      sx={{
        overflow: 'overlay',
        ...cardContainerStyles,
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        <Typography hidden={!title} variant="title" component="h3">
          {title}
        </Typography>
        <Box sx={{ ml: 'auto' }}>{menu}</Box>
      </Box>
      {children}
    </Box>
  );
});
