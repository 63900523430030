import { noteFieldTextStyles } from '@/utils/theme';
import { useTheme } from '@mui/material';
import { sanitize } from 'isomorphic-dompurify';
import { FC } from 'react';

interface IProps {
  note: string;
}
const ExternalNoteField: FC<IProps> = ({ note }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        minHeight: '2rem',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem',
        ...noteFieldTextStyles,
        overflowY: 'auto',
        width: '100%',
        whiteSpace: 'pre-wrap',
        borderRadius: 4,
        marginBottom: '1rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      dangerouslySetInnerHTML={{
        __html: sanitize(note),
      }}
    />
  );
};
export default ExternalNoteField;
