import { CODE_CATEGORY_MODIFIER, DEFAULT_CODE_MODIFIERS } from '@/config/constants';
import useCases from '@/hooks/useCases';
import { codeModifierSearched } from '@/services/analytics';
import { searchForCode } from '@/services/BackendAPI';
import { ICode } from '@/types/ICode';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';
import { Autocomplete, Box, debounce, InputBase, Paper, Typography, Chip } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useState } from 'react';

const MODIFIER_SEARCH_TIMEOUT = 300;

interface IProps {
  code: ICode;
  defaultModifiers: ITerminologyCodeRead[];
}
export const CodeModifierSelector: FC<IProps> = observer(({ code, defaultModifiers }) => {
  const cases = useCases();

  const [options, setOptions] = useState<ITerminologyCodeRead[]>(DEFAULT_CODE_MODIFIERS);

  const [searchValue, setSearchValue] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');

  const handleSearch = (newInputValue: string) => {
    if (newInputValue.trim() === '') {
      setOptions(DEFAULT_CODE_MODIFIERS);
      return;
    }

    searchForCode(newInputValue, [CODE_CATEGORY_MODIFIER], cases.config.code_systems).then(
      (response) => {
        setOptions(response);
        setSearchValue(newInputValue);
        codeModifierSearched(cases.caseId, code.name, newInputValue);
      },
    );
  };

  const handleSearchDebounced = useCallback(debounce(handleSearch, MODIFIER_SEARCH_TIMEOUT), [
    searchValue,
  ]);

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputText(newInputValue);
    handleSearchDebounced(newInputValue);
  };

  const handleOnChange = (event: any, newInputValue: ITerminologyCodeRead[] | null) => {
    if (newInputValue == null) {
      return;
    }

    cases.changeCodeModifiers(code, newInputValue);
  };

  const isOptionEqualToValue = (option: ITerminologyCodeRead, value: ITerminologyCodeRead) => {
    return option.name === value.name;
  };

  return (
    <Box sx={{ maxWidth: '300px', width: '100%', mt: 0.5 }}>
      <Autocomplete
        noOptionsText="No results"
        disablePortal
        autoHighlight
        freeSolo
        limitTags={2}
        value={code.new_modifiers}
        filterOptions={(x) => x}
        onInputChange={handleInputChange}
        onChange={handleOnChange}
        options={options}
        inputValue={inputText}
        multiple={true}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} key={option.name} label={option.name} size="small" />
          ));
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} data-dd-action-name="Select modifier" key={option.name}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontWeight: 700, textAlign: 'justify' }}>{option.name}</Typography>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                {option.description}
              </Typography>
            </Box>
          </Box>
        )}
        getOptionLabel={(option: ITerminologyCodeRead) => option.name}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <Paper
              variant="outlined"
              sx={{
                p: 0,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
              }}
            >
              <InputBase
                placeholder="Søg efter modifier..."
                sx={{ px: 0.4, py: 0.2 }}
                {...params.InputProps}
                {...rest}
              />
            </Paper>
          );
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        disableClearable={true}
        sx={{
          '& .MuiAutocomplete-tag': {
            fontSize: '16px',
            lineHeight: '16px',
            margin: '1px',
            height: '17px',
            maxWidth: 'calc(100% - 1px)',
            '& .MuiChip-label': {
              paddingLeft: '6px',
              paddingRight: '10px',
            },
            '& .MuiChip-deleteIcon': {
              fontSize: '13px',
              margin: '0 3px 0 -4px',
            },
          },
          '& .MuiAutocomplete-input': {
            padding: '0 3px',
            fontSize: '14px',
          },
        }}
      />
    </Box>
  );
});
