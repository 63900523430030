import { ECaseStatus } from '@/types/ECaseStatus';
import { IAnnotatedReadCase } from '@/types/IAnnotatedReadCase';
import { ICaseTableFilters } from '@/types/ICaseTableFilters';
import { ICaseTableFiltersExtended } from '@/types/ICaseTableFiltersExtended';
import { ISelectOption } from '@/types/ISelectOption';
import { ITableCase } from '@/types/ITableCase';
import { ITableCaseBase } from '@/types/ITableCaseBase';
import { ITableFilterOptions } from '@/types/ITableFilterOptions';
import escapeStringRegexp from 'escape-string-regexp';
import { formatDateToNumber, formatDateWithDuration } from './dates';
import { formatCaseLocked } from './misc';

export const createSearchFilterFn =
  (filters: ICaseTableFiltersExtended | null) => (caseItem: ITableCaseBase) => {
    const filtersMatch = doesCaseMatchFilters(filters, caseItem);
    if (!filtersMatch) {
      return false;
    }

    const searchPhrase = (filters?.query || '').trim();

    // If searchPhrase is not set, then we only rely on filters which have already matched by this time.
    if (searchPhrase.length === 0) {
      return true;
    }

    const preparedSearchPhrase = escapeStringRegexp(searchPhrase);
    const flagReasonMatches =
      (caseItem.flag_reason + caseItem.patient_id.toString()).search(
        new RegExp(preparedSearchPhrase, 'i'),
      ) !== -1;

    return flagReasonMatches;
  };

/**
 * Check the case fields against the filter values.
 * Takes into account array fields only.
 *
 * @param filters
 * @param caseItem
 * @returns
 */
const doesCaseMatchFilters = (
  filters: ICaseTableFilters | null,
  caseItem: ITableCaseBase,
): boolean => {
  // If no filter set, then display all.
  if (filters == null) {
    return true;
  }

  // If no filter set, then display all.
  const keys = Object.keys(filters);
  if (keys.length === 0) {
    return true;
  }

  // If no filter set, then display all.
  let noFilterSet = true;
  for (let key in filters) {
    if (filters[key] != null) {
      noFilterSet = false;
    }
  }
  if (noFilterSet) {
    return true;
  }

  for (let key in filters) {
    if (filters[key] == null || filters[key].length === 0 || !Array.isArray(filters[key])) {
      continue;
    }
    if (!filters[key].includes(caseItem[key])) {
      return false;
    }
  }

  return true;
};

/**
 * Collect and prepare filter values.
 *
 * @param {ITableCase[]} tableCases
 * @param {ITableFilterOptions} defaultFilterOptions
 * @returns {ITableFilterOptions}
 */
export const collectFilterValues = (
  tableCases: ITableCase[],
  defaultFilterOptions: ITableFilterOptions,
): ITableFilterOptions => {
  const options: ITableFilterOptions = defaultFilterOptions;
  const fieldsToCheck = ['location', 'specialty', 'consultation_type', 'doctor_initials'];
  tableCases.forEach((caseItem: ITableCase) => {
    fieldsToCheck.forEach((field) => {
      if (caseItem[field] && !options[field].includes(caseItem[field])) {
        options[field].push(caseItem[field]);
      }
    });
  });
  return options;
};

export const mapReceivedCaseToTableCase = (
  receivedCase: IAnnotatedReadCase,
  isNew: boolean = false,
): ITableCase => {
  // merge all consultation types of 'Operation*' into one called 'Operation'
  const consultationType = receivedCase.consultation_type?.startsWith('Operation')
    ? 'Operation'
    : receivedCase.consultation_type || '';

  return {
    id: receivedCase.id,
    created_at: formatDateToNumber(receivedCase.created_at),
    created_at_formatted: formatDateWithDuration(receivedCase.created_at) || '',
    patient_id: receivedCase.patient_id || '',
    external_id: receivedCase.external_id || '',
    doctor_initials: receivedCase.doctor_initials || '',
    flag_reason: receivedCase.flag_reason || '',
    urgent: receivedCase.urgent,
    consultation_type: consultationType,
    specialty: receivedCase.specialty || '',
    location: receivedCase.location || '',
    submitted_by: receivedCase.submitted_by || '',
    locked:
      receivedCase.status !== ECaseStatus.SUBMITTED ? formatCaseLocked(receivedCase.locked) : null,
    locked_by_me: receivedCase.locked ? receivedCase.locked.locked_by_me : false,
    isNew: isNew || false,
  };
};

export const addFilterValueToFilters = (
  filters: ICaseTableFiltersExtended,
  selectedOption: ISelectOption | ISelectOption[],
  field: string,
): ICaseTableFilters => {
  const newFilters: ICaseTableFiltersExtended = {
    ...filters,
  };

  if (selectedOption == null) {
    delete newFilters[field];
  }

  if (Array.isArray(selectedOption)) {
    newFilters[field] = selectedOption.map((option) => option.value);
  } else {
    newFilters[field] = [selectedOption.value];
  }
  return newFilters;
};
