import { Box } from '@mui/material';
import { FC } from 'react';
import InboxTabs from './InboxTabs';
import { WelcomeBox } from './WelcomeBox';

const InboxTopBarContent: FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    }}
  >
    <WelcomeBox />
    <InboxTabs />
  </Box>
);
export default InboxTopBarContent;
