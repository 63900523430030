import { ISelectOption } from '@/types/ISelectOption';
import { createSelectOption } from '@/utils/misc';
import { SvgIconComponent } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FilterOptionsState,
  InputAdornment,
  InputBase,
  Paper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import escapeStringRegexp from 'escape-string-regexp';
import { FC, useEffect, useState } from 'react';

interface IProps {
  Icon?: SvgIconComponent;
  placeholder?: string;
  defaultValue?: string | string[];
  options: ISelectOption[];
  multiple?: boolean;
  onChange: (selectedOptions: ISelectOption | ISelectOption[] | null) => void;
  sx: SxProps<Theme>;
}
export const SearchableSelect: FC<IProps> = ({
  defaultValue,
  options,
  placeholder,
  onChange,
  Icon,
  sx,
  multiple = false,
}) => {
  const [value, setValue] = useState<ISelectOption | ISelectOption[]>(multiple ? [] : null);

  const handleChange = (event: any, selectedOptions: ISelectOption | ISelectOption[] | null) => {
    onChange(selectedOptions);
  };

  const isOptionEqualToValue = (option: ISelectOption, value: ISelectOption) => {
    return option.value === value.value;
  };

  const prepareFilterOptions = (
    options: ISelectOption[],
    state: FilterOptionsState<ISelectOption>,
  ) => {
    if (state.inputValue == null || state.inputValue.trim() === '') {
      return options;
    }

    const searchableValue = escapeStringRegexp(state.inputValue);
    return options.filter(
      (option: ISelectOption) => option.label.search(new RegExp(searchableValue, 'i')) !== -1,
    );
  };

  const prepareDefaultValues = (defaultValues?: string | string[]): ISelectOption[] => {
    if (defaultValues == null) {
      return [];
    }

    let values = [];
    if (!Array.isArray(defaultValues)) {
      values.push(defaultValues);
    } else {
      values = [...defaultValues];
    }

    return values.filter((x) => x).map((value: string) => createSelectOption(value)) || [];
  };

  useEffect(() => {
    setValue(prepareDefaultValues(defaultValue));
  }, [defaultValue]);

  return (
    <Box sx={{ ...sx }}>
      <Autocomplete
        noOptionsText="No results"
        disablePortal={false}
        autoHighlight
        filterOptions={prepareFilterOptions}
        onChange={handleChange}
        options={options}
        value={value}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            height: '40px',
          },
        }}
        multiple={multiple}
        limitTags={2}
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-end' } }}
        getOptionLabel={(option: ISelectOption) => option.label}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <Paper
              variant="outlined"
              sx={{
                py: 0,
                px: 1,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
              }}
            >
              {Icon && (
                <InputAdornment position="start" sx={{ mx: 0.6 }}>
                  <Icon color="secondary" />
                </InputAdornment>
              )}
              <InputBase
                placeholder={placeholder || 'Select an item'}
                {...params.InputProps}
                {...rest}
              />
            </Paper>
          );
        }}
        isOptionEqualToValue={isOptionEqualToValue}
      />
    </Box>
  );
};
