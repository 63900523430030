import { ECaseStatus } from '@/types/ECaseStatus';
import { ICreateCaseInput } from '@/types/ICreateCaseInput';
import { IWebNewCaseData } from '@/types/web-only/IWebNewCaseData';
import { randomInt } from '@/utils/misc';
import random from 'random-string-generator';

export const WEB_NEW_CASE_CONSULTATION_TYPES = [
  'Forundersøgelse',
  'Telefonkonsultation',
  'Konsultation',
  'Kontrol',
  'Operation',
  'Operation - Forundersøgelse',
  'Operation - Kontrol',
];

export interface IWebNewCaseDoctor {
  doctor_id: number;
  doctor_initials: string;
  specialty: string;
}
export const WEB_NEW_CASE_DOCTORS: IWebNewCaseDoctor[] = [
  {
    doctor_id: 123,
    doctor_initials: 'PAL',
    specialty: 'Ortopædisk kirurgi',
  },
  {
    doctor_id: 456,
    doctor_initials: 'KAL',
    specialty: 'Kirurgisk gastroenterologi',
  },
  {
    doctor_id: 789,
    doctor_initials: 'WAL',
    specialty: 'Reumatologi',
  },
];

export const WEB_NEW_CASE_LOCATIONS = ['Hellerup', 'Brøndby', 'Aalborg'];

export const generateRandomCaseData = (): IWebNewCaseData => {
  const consultationTypeIndex = randomInt(0, WEB_NEW_CASE_CONSULTATION_TYPES.length - 1);
  const doctorIndex = randomInt(0, WEB_NEW_CASE_DOCTORS.length - 1);
  const locationIndex = randomInt(0, WEB_NEW_CASE_LOCATIONS.length - 1);

  return {
    consultationType: WEB_NEW_CASE_CONSULTATION_TYPES[consultationTypeIndex],
    doctorId: WEB_NEW_CASE_DOCTORS[doctorIndex].doctor_id.toString(),
    doctorInitials: WEB_NEW_CASE_DOCTORS[doctorIndex].doctor_initials,
    specialty: WEB_NEW_CASE_DOCTORS[doctorIndex].specialty,
    location: WEB_NEW_CASE_LOCATIONS[locationIndex],
    existingCodes: [],
    externalId: '',
    externalNoteId: '',
    patientId: randomPatientId(),
    status: ECaseStatus.NEW,
  };
};

export const randomPatientId = () => {
  return random(6, 'numeric');
};

export const mapWebNewCaseDataToCreateCaseInput = (
  webNewCaseData: Partial<IWebNewCaseData>,
): Partial<ICreateCaseInput> => {
  if (webNewCaseData == null) {
    return {};
  }

  const params: Partial<ICreateCaseInput> = {
    consultation_type: webNewCaseData.consultationType,
  };

  if (webNewCaseData.doctorId == null) {
    return params;
  }

  params.doctor_id = webNewCaseData.doctorId;
  params.doctor_initials = webNewCaseData.doctorInitials;
  params.specialty = webNewCaseData.specialty;
  params.location = webNewCaseData.location;
  params.existing_codes = webNewCaseData.existingCodes;
  params.external_id = webNewCaseData.externalId;
  params.external_note_id = webNewCaseData.externalNoteId;
  params.patient_id = webNewCaseData.patientId;
  params.status = webNewCaseData.status;

  return params;
};
