import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box } from '@mui/material';
import { orange } from '@mui/material/colors';
import { FC } from 'react';

const DuplicateCodesWarning: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: '14px',
        mt: 2,
        mb: -2,
      }}
    >
      <Box
        sx={{
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid ' + orange[500],
          backgroundColor: orange[500],
          color: '#ffffff',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          p: 1,
        }}
      >
        <PriorityHighIcon color="inherit" sx={{ width: '24px', height: '24px' }} />
      </Box>
      Duplicate code detected. Please verify if the duplication was intentional. If it was not,
      please remove the duplicate. Proceed only if you intended to add the same code multiple times.
    </Box>
  );
};

export default DuplicateCodesWarning;
