import { ROLE_BETA_TESTER, ROLE_ALPHA_TESTER, ROLE_SECRETARY } from '@/config/constants';
import keycloak from './keycloak/init';

export const isAlphaTester = () => keycloak.hasRealmRole(ROLE_ALPHA_TESTER);
export const isBetaTester = () => keycloak.hasRealmRole(ROLE_BETA_TESTER);

// force this feature OFF for all
// export const isLLMDoctor = () => false;
export const isLLMDoctor = () => !keycloak.hasRealmRole(ROLE_SECRETARY);

export const isStreamASRDoctor = () => !keycloak.hasRealmRole(ROLE_SECRETARY);

export const isAlphaSecretary = () =>
  keycloak.hasRealmRole(ROLE_ALPHA_TESTER) && keycloak.hasRealmRole(ROLE_SECRETARY);
