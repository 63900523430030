import useCases from '@/hooks/useCases';
import { ECaseStatus } from '@/types/ECaseStatus';
import { IWebNewCaseData } from '@/types/web-only/IWebNewCaseData';
import { cloudCards } from '@/utils/theme';
import {
  IWebNewCaseDoctor,
  WEB_NEW_CASE_CONSULTATION_TYPES,
  WEB_NEW_CASE_DOCTORS,
  generateRandomCaseData,
  randomPatientId,
} from '@/utils/web-only/cases';
import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { FC, MouseEvent, PropsWithChildren, useEffect, useState } from 'react';

enum EMode {
  TEMPLATE_SELECTION,
  RANDOM_VALUES,
  MANUAL_VALUES,
  VOID,
}

const DEFAULT_OPTION = '--';

interface IWebNewCaseDialog {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}
const WebNewCaseDialog: FC<IWebNewCaseDialog> = ({ open = false, onClose, onDone }) => {
  const [mode, setMode] = useState<EMode>(EMode.VOID);

  const handleTemplateControlClick = () => {
    setMode(EMode.TEMPLATE_SELECTION);
  };

  const handleSelectValuesClick = () => {
    setMode(EMode.MANUAL_VALUES);
  };
  const handleCancel = () => {
    setMode(EMode.VOID);
  };

  const handleDone = () => {
    onDone();
    setMode(EMode.VOID);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '640px',
          ...cloudCards,
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: '1px solid ' + grey[200], mb: 4 }}>Create case</DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', p: 4, minWidth: '300px' }}
      >
        {mode === EMode.TEMPLATE_SELECTION && (
          <CaseNoteTemplateSelector onSelect={handleDone} onCancel={handleCancel} />
        )}

        {mode === EMode.MANUAL_VALUES && (
          <NewCaseManualValuesForm onSelect={handleDone} onCancel={handleCancel} />
        )}

        {mode === EMode.VOID && (
          <>
            <ChoseTemplateControl onSelect={handleTemplateControlClick} />
            <RandomCaseCreateControl onSelect={handleDone} />
            <SelectValuesCaseCreateControl onSelect={handleSelectValuesClick} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WebNewCaseDialog;

interface IChoseTemplateControl {
  onSelect: () => void;
}
const ChoseTemplateControl: FC<IChoseTemplateControl> = ({ onSelect }) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSelect();
  };

  return <SecondaryActionButton onClick={handleClick}>Choose template</SecondaryActionButton>;
};

interface IRandomCaseCreateControl {
  onSelect: () => void;
}
const RandomCaseCreateControl: FC<IRandomCaseCreateControl> = ({ onSelect }) => {
  const cases = useCases();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    cases.setWebNewCaseData(generateRandomCaseData());

    onSelect();
  };

  return <SecondaryActionButton onClick={handleClick}>Random values</SecondaryActionButton>;
};

interface IRandomCaseCreateControl {
  onSelect: () => void;
}
const SelectValuesCaseCreateControl: FC<IRandomCaseCreateControl> = ({ onSelect }) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSelect();
  };

  return <SecondaryActionButton onClick={handleClick}>Select values</SecondaryActionButton>;
};

interface ICaseNoteTemplateSelector {
  onSelect: () => void;
  onCancel: () => void;
}
const CaseNoteTemplateSelector: FC<ICaseNoteTemplateSelector> = observer(
  ({ onSelect, onCancel }) => {
    const cases = useCases();

    const handleListItemClick = (value: string) => {
      const templateId = value === DEFAULT_OPTION ? undefined : value;
      cases.setWebNewCaseData({ consultationType: templateId });

      onSelect();
    };

    const handleCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      onCancel();
    };

    const templateIds = [DEFAULT_OPTION].concat(cases.templateIds);

    return (
      <Box>
        <Box sx={{ fontWeight: 'bold', mb: 2 }}>Choose a template</Box>
        <List sx={{ p: 0, maxHeight: '400px', overflowY: 'auto', mb: 2 }}>
          {templateIds.map((templateId: string) => (
            <ListItem disableGutters key={templateId}>
              <ListItemButton onClick={() => handleListItemClick(templateId)}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={templateId === DEFAULT_OPTION ? 'Default' : templateId} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <BackButton onClick={handleCancelClick} />
      </Box>
    );
  },
);

const defaultNewCaseData: Partial<IWebNewCaseData> = {
  consultationType: WEB_NEW_CASE_CONSULTATION_TYPES[0],
  doctorId: WEB_NEW_CASE_DOCTORS[0].doctor_id.toString(),
  doctorInitials: WEB_NEW_CASE_DOCTORS[0].doctor_initials,
  specialty: WEB_NEW_CASE_DOCTORS[0].specialty,
  patientId: randomPatientId(),
  status: ECaseStatus.NEW,
};

interface INewCaseManualValuesForm {
  onSelect: () => void;
  onCancel: () => void;
}
const NewCaseManualValuesForm: FC<INewCaseManualValuesForm> = observer(({ onSelect, onCancel }) => {
  const cases = useCases();

  const [data, setData] = useState<Partial<IWebNewCaseData>>(defaultNewCaseData);

  const handleSubmitClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    cases.setWebNewCaseData(data);
    onSelect();
  };
  const handleBackClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCancel();
  };

  useEffect(() => {
    setData({
      ...defaultNewCaseData,
      patientId: randomPatientId(),
    });
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <TextField
        select
        label="Konsultationstype"
        value={data?.consultationType}
        variant="standard"
        onChange={(event) => {
          setData({ ...data, consultationType: event.target.value });
        }}
      >
        {WEB_NEW_CASE_CONSULTATION_TYPES.map((type: string) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        value={Number(data?.doctorId)}
        label="Doctor ID"
        variant="standard"
        onChange={(event) => {
          const id = Number(event.target.value);
          const doctor = WEB_NEW_CASE_DOCTORS.find((doctor) => doctor.doctor_id === id);
          setData({
            ...data,
            doctorId: id.toString(),
            doctorInitials: doctor.doctor_initials,
            specialty: doctor.specialty,
          });
        }}
      >
        {WEB_NEW_CASE_DOCTORS.map((doctor: IWebNewCaseDoctor) => (
          <MenuItem key={doctor.doctor_id} value={doctor.doctor_id}>
            {doctor.doctor_initials + ': ' + doctor.specialty}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant="standard"
        InputProps={{
          readOnly: true,
        }}
        label="Doctor Initials"
        value={data?.doctorInitials || ''}
      />
      <TextField
        variant="standard"
        InputProps={{
          readOnly: true,
        }}
        label="Specialty"
        value={data?.specialty || ''}
      />
      <TextField
        variant="standard"
        label="Location"
        value={data?.location || ''}
        onChange={(event) => {
          setData({
            ...data,
            location: event.target.value,
          });
        }}
      />
      <TextField
        variant="standard"
        label="External ID"
        value={data?.externalId || ''}
        onChange={(event) => {
          setData({
            ...data,
            externalId: event.target.value,
          });
        }}
      />
      <TextField
        variant="standard"
        label="External Note ID"
        value={data?.externalNoteId || ''}
        onChange={(event) => {
          setData({
            ...data,
            externalNoteId: event.target.value,
          });
        }}
      />
      <TextField
        variant="standard"
        label="Patient ID"
        value={data?.patientId || ''}
        onChange={(event) => {
          setData({
            ...data,
            patientId: event.target.value,
          });
        }}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', mt: 2 }}>
        <BackButton onClick={handleBackClick} />
        <PrimaryActionButton onClick={handleSubmitClick}>Create case</PrimaryActionButton>
      </Box>
    </Box>
  );
});

interface IBackButton {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}
const BackButton: FC<IBackButton> = ({ onClick }) => (
  <SecondaryActionButton onClick={onClick}>Back</SecondaryActionButton>
);

interface ISecondaryActionButton {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}
const SecondaryActionButton: FC<PropsWithChildren<ISecondaryActionButton>> = ({
  children,
  onClick,
}) => (
  <Button variant="contained" size="large" color="neutral" onClick={onClick}>
    {children}
  </Button>
);

interface IPrimaryActionButton {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}
const PrimaryActionButton: FC<PropsWithChildren<IPrimaryActionButton>> = ({
  children,
  onClick,
}) => (
  <Button variant="contained" size="large" color="secondary" onClick={onClick}>
    {children}
  </Button>
);
