import { CODE_CATEGORY_DIAGNOSIS } from '@/config/constants';
import useCases from '@/hooks/useCases';
import { ECaseStatus } from '@/types/ECaseStatus';
import { ICode } from '@/types/ICode';
import { codeSortHandler } from '@/utils/codes';
import { cloudCards, flexColumnFullHeight } from '@/utils/theme';
import PendingIcon from '@mui/icons-material/Pending';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { CardContainer } from '../layout/CardContainer';
import { StrictModeDroppable } from '../misc/StrictModeDroppable';
import { CodeCard } from './CodeCard';
import CodeTypeHeader from './CodeTypeHeader';

export const SelectedCodes = observer(() => {
  const cases = useCases();

  const onDragEnd = (result) => {
    // Do nothing if dropped outside the list.
    if (!result.destination) {
      return;
    }

    // We only support reordering of the Diagnosis codes.
    if (diagnosisCodes.length === 0) {
      return;
    }

    // Reorder diagnosis codes.
    const reorderedDiagnosisCodes = reorderList(
      diagnosisCodes,
      result.source.index,
      result.destination.index,
    );

    // Merge reordered diagnosis codes with others, and save the changes to the cases store.
    cases.setSelectedCodes(reorderedDiagnosisCodes.concat(otherCodes));
  };

  const codes = cases.selectedCodes.slice().sort(codeSortHandler);
  const diagnosisCodes = codes.filter((code: ICode) => code.type === CODE_CATEGORY_DIAGNOSIS);

  const otherCodes = codes.filter((code: ICode) => code.type !== CODE_CATEGORY_DIAGNOSIS);

  return (
    <CardContainer
      title="Valgte koder"
      sx={{
        ...flexColumnFullHeight,
        // Subtract padding of the container from the full height.
        height: '100%',
      }}
    >
      <Box
        sx={{
          pr: 2,
          mr: -1,
          pb: 2,
          overflowY: 'auto',
        }}
        className="cytest-codes-container"
      >
        {/* empty state if there are no codes */}
        {codes.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              padding: 4,
              borderRadius: 2,
              ...cloudCards,
            }}
          >
            <PendingIcon sx={{ fontSize: 40, color: 'action.disabled' }} />
            <Typography variant="body2" color="textSecondary">
              Ingen koder valgt
            </Typography>
          </Box>
        )}

        {/* Code reordering is only allowed for diagnosis codes of the cases which are not yet completed. */}
        {cases.status !== ECaseStatus.SUBMITTED && diagnosisCodes.length > 0 && (
          <ReorderableCodeCategory
            categoryName={CODE_CATEGORY_DIAGNOSIS}
            codes={diagnosisCodes}
            dragEndHandler={onDragEnd}
          />
        )}

        {cases.status === ECaseStatus.SUBMITTED && diagnosisCodes.length > 0 && (
          <NonReorderableCodeCategory
            categoryName={CODE_CATEGORY_DIAGNOSIS}
            codes={diagnosisCodes}
          />
        )}

        {/* We assume that we have only two code categories/types for now. */}
        {otherCodes.length > 0 && (
          <NonReorderableCodeCategory categoryName={otherCodes[0].type} codes={otherCodes} />
        )}
      </Box>
    </CardContainer>
  );
});

const reorderList = (list: any[], startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface INonReorderableCodeCategoryProps {
  categoryName: string;
  codes: ICode[];
}
const NonReorderableCodeCategory: FC<INonReorderableCodeCategoryProps> = observer(
  ({ categoryName, codes }) => {
    const cases = useCases();

    return (
      <Box
        key={categoryName}
        sx={{
          mb: 2,
          '&:nth-last-of-type(1)': {
            mb: 0,
          },
        }}
      >
        <CodeTypeHeader>{categoryName}</CodeTypeHeader>
        <Stack
          spacing={0}
          sx={{
            display: cases.areSelectedCodesVisible() ? 'flex' : 'none',
            gap: 1,
          }}
        >
          {codes.map((code: ICode, index: number) => (
            <CodeCard
              index={index}
              key={code._internalId}
              code={code}
              edit={cases.codesEditMode}
              removeHandler={() => cases.removeSelectedCode(code)}
              areModifiersEditable={true}
            />
          ))}
        </Stack>
      </Box>
    );
  },
);

interface IReorderableCodeCategoryProps {
  categoryName: string;
  codes: ICode[];
  dragEndHandler: (result: any) => void;
}
const ReorderableCodeCategory: FC<IReorderableCodeCategoryProps> = observer(
  ({ categoryName, codes, dragEndHandler }) => {
    const cases = useCases();
    const theme = useTheme();

    return (
      <Box
        key={categoryName}
        sx={{
          mb: 2,
          '&:nth-last-of-type(1)': {
            mb: 0,
          },
        }}
      >
        <CodeTypeHeader>
          {categoryName}
          <SyncAltIcon
            sx={{
              width: '1rem',
              color: theme.palette.secondary.main,
              transform: 'rotate(90deg)',
              ml: 0.5,
            }}
          />
        </CodeTypeHeader>

        <DragDropContext onDragEnd={dragEndHandler}>
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshot) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={0}
                sx={{
                  display: cases.areSelectedCodesVisible() ? 'flex' : 'none',
                  border: snapshot.isDraggingOver
                    ? '1px dashed ' + theme.palette.secondary.main
                    : null,
                  borderRadius: 2,
                  gap: 1,
                }}
              >
                {codes.map((code: ICode, index: number) => (
                  <Draggable key={code._internalId} draggableId={code._internalId} index={index}>
                    {(provided, snapshot) => (
                      <ObservedDraggableCodeCard
                        provided={provided}
                        snapshot={snapshot}
                        code={code}
                        index={index}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Box>
    );
  },
);

/**
 * We need to encapsulate the code card and observe it explicitly when it's wrapped by a Draggable component.
 */
const ObservedDraggableCodeCard = observer(({ provided, snapshot, code, index }) => {
  const theme = useTheme();
  const cases = useCases();
  return (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-dd-action-name="Reorder code"
    >
      <CodeCard
        index={index}
        code={code}
        edit={cases.codesEditMode}
        removeHandler={() => cases.removeSelectedCode(code)}
        areModifiersEditable={true}
        sx={
          snapshot.isDragging
            ? {
                borderColor: theme.palette.secondary.main,
                borderStyle: 'dashed',
              }
            : {}
        }
      />
    </Box>
  );
});
