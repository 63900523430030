// volumeProcessor.ts

export const setupVolumeProcessor = (
  mediaStream: MediaStream,
  onVolumeUpdate: (normalizedVolume: number) => void,
  throttleTime: number = 50, // Throttle updates to once every 100ms (10 times per second)
) => {
  const audioContext = new AudioContext();
  const analyser = audioContext.createAnalyser();
  const source = audioContext.createMediaStreamSource(mediaStream);

  source.connect(analyser);

  analyser.fftSize = 64; // Reduce fftSize to 128 (less detailed, but faster)
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  let animationId: number;
  let lastUpdateTime = 0;

  const updateVolume = (time: number) => {
    if (time - lastUpdateTime >= throttleTime) {
      analyser.getByteFrequencyData(dataArray);

      // Calculate the average volume
      const sum = dataArray.reduce((acc, val) => acc + val, 0);
      const average = sum / bufferLength;
      const normalizedVolume = Math.min(average / 140, 1);

      onVolumeUpdate(normalizedVolume);
      lastUpdateTime = time;
    }

    animationId = requestAnimationFrame(updateVolume);
  };

  animationId = requestAnimationFrame(updateVolume);

  return () => {
    cancelAnimationFrame(animationId);
    source.disconnect();
    audioContext.close();
  };
};
