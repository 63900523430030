import { AlertBar } from '@/components/layout/AlertBar';
import useForceRedirects from '@/hooks/useForceRedirects';
import { wallpaperStyles } from '@/utils/theme';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

/**
 * The RootPage is responsible for the main layout of the app which is inherited by all sub-routes.
 */
export const RootPage = observer(() => {
  /// We use RootPage component for force redirects (bypassing all other conditions/restrictions)
  /// because it's higher in the order.
  useForceRedirects();

  // check if query param skipBlocker is set to true or in session storage
  // if so, we skip the blocker page and add to session storage

  const urlParams = new URLSearchParams(window.location.search);
  let skipBlocker = !!sessionStorage.getItem('skipBlocker');
  if (urlParams.has('skipBlocker')) {
    skipBlocker = true;
    sessionStorage.setItem('skipBlocker', 'true');
  }

  // get the hostname from the URL
  const isNonK8s =
    !window.location.hostname.includes('corti.app') &&
    !window.location.hostname.includes('localhost') &&
    !skipBlocker;
  return (
    <Box sx={{ display: 'flex', ...wallpaperStyles }}>
      <Box
        sx={{
          minHeight: '100vh',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isNonK8s ? <BigMessage /> : <Outlet />}
        <AlertBar />
      </Box>
    </Box>
  );
});

const BigMessage = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>Corti er blevet opgraderet</h1>
      <p>Genstart din computer for at få den seneste version</p>
      <p>OBS: Hvis du stadig ser denne fejl efter genstart, kontakt venligst Veniamin</p>
    </Box>
  );
};
