import { FC } from 'react';
import TableRowMessage from './TableRowMessage';

interface IProps {
  colSpan: number;
}
const TableRowNoCasesFound: FC<IProps> = ({ colSpan }) => (
  <TableRowMessage
    colSpan={colSpan}
    message="No matching cases. Please try to adjust filter settings."
  />
);

export default TableRowNoCasesFound;
