import LockIcon from '@mui/icons-material/Lock';
import { Tooltip, useTheme } from '@mui/material';
import { FC } from 'react';

interface ILockFlagProps {
  lockedDetails: string;
  lockedByMe: boolean;
}
const LockFlag: FC<ILockFlagProps> = ({ lockedDetails, lockedByMe = false }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={lockedDetails}
      sx={{ color: lockedByMe ? theme.palette.secondary.main : theme.palette.primary.dark }}
    >
      <LockIcon />
    </Tooltip>
  );
};

export default LockFlag;
