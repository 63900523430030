import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { transaction } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/App';
import themeSettings from './config/theme';
import { AlertContext } from './context/AlertContext';
import { CaseContext } from './context/CaseContext';
import { UserContext } from './context/UserContext';
import { UserPreferenceContext } from './context/UserPreferenceContext';
import AuthProvider from './providers/AuthProvider';
import SettingsProvider from './providers/SettingsProvider';
import { authUpdateOnReceivingNewCase } from './services/keycloak/helpers';
import keycloak from './services/keycloak/init';
import { AlertStore } from './store/AlertStore';
import { CaseStore } from './store/CaseStore';
import { UserPreferenceStore } from './store/UserPreferenceStore';
import { UserStore } from './store/UserStore';
import { isInsideElectron } from './utils/misc';

const theme = createTheme(themeSettings);

const alertStore = new AlertStore();
const userStore = new UserStore();
const caseStore = new CaseStore(alertStore, userStore);
const userPreferenceStore = new UserPreferenceStore();

if (isInsideElectron()) {
  // On receiving a case from EHR.
  window.cortiCodeAPI.onNewCase((_event: string, value: any) => {
    // If a case is open and it has not yet been flagged or completed,
    // we need to warn the user and ask to finish current case first.
    if (
      !caseStore.isFlagged() &&
      !caseStore.isCompleted() &&
      (caseStore.hasRecordingBeenUsed() || !caseStore.isNoteEmpty())
    ) {
      caseStore.enableCurrentCaseNotSavedWarning();
      return;
    }

    // Check authentication before creating a new case.
    authUpdateOnReceivingNewCase(keycloak).then(() => {
      // Clear the case and init it with EHR data at once.
      transaction(() => {
        // Prepare the case store by clearing it.
        caseStore.clearCase();
        // Fill the case store with the case details, received from EHR.
        caseStore.initStoreWithNewCase(value);
      });

      // Create a new case, based on the case details, received from EHR.
      caseStore.createNewCase().then(() => {
        // Force redirect to /newehr page.
        caseStore.enableForceRedirectToNewEhr();
      });
    });
  });
} else {
  console.log('No external API detected');
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <SettingsProvider>
    <AuthProvider userStore={userStore} caseStore={caseStore} alertStore={alertStore}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UserContext.Provider value={userStore}>
            <AlertContext.Provider value={alertStore}>
              <CaseContext.Provider value={caseStore}>
                <UserPreferenceContext.Provider value={userPreferenceStore}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                  </LocalizationProvider>
                </UserPreferenceContext.Provider>
              </CaseContext.Provider>
            </AlertContext.Provider>
          </UserContext.Provider>
        </ThemeProvider>
      </React.StrictMode>
    </AuthProvider>
  </SettingsProvider>,
);

postMessage({ payload: 'removeLoading' }, '*');
