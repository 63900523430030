import {
  Autocomplete,
  Box,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { observer } from 'mobx-react';
import { FC, SyntheticEvent, useState } from 'react';

import useSearchBarClient from '@/hooks/useSearchBarClient';
import { IPreparedCode, ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';
import { blue } from '@mui/material/colors';

interface IProps {
  codeSystems: ITerminologyCodeRead['code_system'][];
}

export const SearchBar: FC<IProps> = observer(({ codeSystems }) => {
  const { options, loading, searchHandler, codeSelectHandler, selectedCodes } =
    useSearchBarClient(codeSystems);

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<null>(null);

  const handleSearch = (e: SyntheticEvent<Element, Event>, newInputValue: string) => {
    searchHandler(newInputValue);
    setInputValue(newInputValue);
  };

  const handleOnChange = (e: SyntheticEvent<Element, Event>, newSelectedValue: IPreparedCode) => {
    if (newSelectedValue) {
      codeSelectHandler(newSelectedValue);
      setInputValue('');
      setSelectedValue(null);
      searchHandler('');
    }
  };

  const isOptionEqualToValue = (option: IPreparedCode, value: IPreparedCode) =>
    option.obj.name === value.obj.name;

  return (
    <Box>
      <Autocomplete
        noOptionsText="No results"
        size="small"
        disablePortal
        autoHighlight
        filterOptions={(x) => x}
        onInputChange={handleSearch}
        onChange={handleOnChange}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        loading={loading}
        getOptionLabel={(option: IPreparedCode) => option.obj.name}
        inputValue={inputValue}
        value={selectedValue}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ ml: 0.5 }}>
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
            placeholder="Søg efter kode"
          />
        )}
        PaperComponent={CustomPaper}
        renderOption={(props, option) => (
          <ListItem {...props} data-dd-action-name="Select code" key={option.obj.name}>
            <ListItemText
              primary={option.renderedItems.name}
              secondary={option.renderedItems.description}
              primaryTypographyProps={{
                fontWeight: 600,
              }}
            />
            {selectedCodes.includes(option.obj.name) && 'Selected'}
          </ListItem>
        )}
      />
    </Box>
  );
});

function CustomPaper({ children }) {
  return (
    <Paper
      className="searchBarPaper"
      sx={{
        marginTop: 1,
        padding: 0.5,
        backgroundColor: 'rgba(255, 255, 255, 0.88)',
        backdropFilter: 'blur(4px)',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.08)',
        border: '1px solid #eaeaea',
        '& .MuiAutocomplete-listbox': {
          padding: 0.5,
        },
        '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
          bgcolor: blue[50],
          borderRadius: 2,
        },
      }}
    >
      {children}
    </Paper>
  );
}
