import useAlerts from '@/hooks/useAlerts';
import { Alert, Snackbar, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

export const AlertBar = observer(() => {
  const alertContext = useAlerts();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    alertContext.hide();
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertContext.show}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          className="dd-privacy-allow"
          onClose={handleClose}
          severity={alertContext.severity}
          sx={{ width: '100%' }}
        >
          {alertContext.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
});
