import fuzzysort from 'fuzzysort';
import { useState, useEffect } from 'react';

import { ITerminologyCodeRead, IPreparedCode } from '@/types/ITerminologyCodeRead';

import useFetchAllCodes from './useFetchAllCodes';

const OPTIONS_COUNT_LIMIT = 20;
const FUZZY_SEARCH_THRESHOLD = -250; // Don't return matches with worse score than this (higher is faster)

const useSearchCodes = (codeSystems: ITerminologyCodeRead['code_system'][]) => {
  const { codes, loading } = useFetchAllCodes(codeSystems);
  const [options, setOptions] = useState<IPreparedCode[]>([]);
  const [codePreferences, setCodePreferences] = useState<[string, number][]>([]);
  const [preparedCodes, setPreparedCodes] = useState<IPreparedCode[]>([]);

  const getCodePreferences = () => {
    const codePreferences = JSON.parse(localStorage.getItem('codePreferences') || '{}') as {
      [key: string]: number;
    };
    let sortedCodes = Object.entries(codePreferences).sort((a, b) => b[1] - a[1]) || [];

    // given the sorted list, give each code a score between 0 and 100 based on its position
    sortedCodes = sortedCodes.map(([code, _], index) => [
      code,
      100 - (index / sortedCodes.length) * 100,
    ]);

    setCodePreferences(sortedCodes);
  };

  const setDefaultOptions = () => {
    if (!codes) return;
    const top20 = codePreferences.slice(0, 20);

    const searchOptions = top20.map(([name]) => {
      const code = codes.find((code) => code.name === name);
      if (!code) return null;
      return {
        obj: code,
        score: 100,
        renderedItems: {
          name: code.name,
          description: code.description,
        },
      };
    });

    setOptions(searchOptions.filter((option) => option !== null));
  };

  const prepareCodes = () => {
    if (!codes) return;
    const codePreferencesMap = new Map(codePreferences);
    const preparedEntries = codes.map((entry: ITerminologyCodeRead) => ({
      obj: entry,
      score: codePreferencesMap.get(entry.name) || 0,
      name_prepared: fuzzysort.prepare(entry.name),
      description_prepared: fuzzysort.prepare(entry.description),
    }));

    setPreparedCodes(preparedEntries);
  };

  useEffect(() => {
    setDefaultOptions();
    getCodePreferences();
    prepareCodes();
  }, [codes]);

  const highlightItem = (item: Fuzzysort.Result): JSX.Element => {
    // Highlight the matched parts of the KeyResult
    let highlighted = fuzzysort.highlight(item, (match, index) => (
      <span className="searchHighlight" key={index}>
        {match}
      </span>
    ));

    return <span>{highlighted.map((d) => d)}</span>;

    // return highlighted;
  };

  const search = (query: string) => {
    if (query === '') {
      setDefaultOptions();
      return;
    }

    let options: Fuzzysort.KeysOptions<IPreparedCode> = {
      keys: ['name_prepared', 'description_prepared'],
      limit: OPTIONS_COUNT_LIMIT,
      threshold: FUZZY_SEARCH_THRESHOLD,
      all: false,
      // Seems the scoreFn type is wrong. It should be (a: Fuzzysort.KeysResult<IPreparedCode>) => number | null
      scoreFn: (a: any) => {
        // slightly bias towards the description not the code name, and bonus for code preferences
        return Math.max(a[0] ? a[0].score - 20 : -1000, a[1] ? a[1].score : -1000) + a.obj.score;
      },
    };

    let results = fuzzysort.go<IPreparedCode>(query, preparedCodes, options);
    const finalSearchResults = results.map((result: Fuzzysort.KeysResult<IPreparedCode>) => {
      const { obj } = result;
      obj.renderedItems = {
        name: result[0] ? highlightItem(result[0]) : obj.obj.name,
        description: result[1] ? highlightItem(result[1]) : obj.obj.description,
      };

      return obj;
    });
    // setOptions(results.map((item: Fuzzysort.KeysResult<IPreparedCode>) => item.obj));
    setOptions(finalSearchResults);
  };

  return { options, loading, search };
};
export default useSearchCodes;
