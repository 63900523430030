import { Theme } from '@mui/material';
import Wallpaper from '../assets/images/wallpaper2.png';

export const blinkStyles = {
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.4 },
  },
};

export const wallpaperStyles = {
  backgroundImage: `url(${Wallpaper})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundAttachment: 'fixed',
};

export const flexColumnFullHeight = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export const cardContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  p: 1,
  m: 1,
  borderRadius: 0,
};

export const cloudCards = {
  background: 'linear-gradient(112deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.6) 100%)',
  boxShadow: 'rgba(186, 142, 255, 0.16) 0px 4px 16px -1px',
  backdropFilter: 'blur(20px)',
};

export const titleStyles = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1.5rem',
  lineHeight: '133.4%',
};

export const borderDivider = (theme: Theme) => '1px solid ' + theme.palette.divider;

export const urgentCaseTableRowStyles = (urgent: boolean) => ({
  backgroundColor: urgent ? 'rgba(245, 0, 87, 0.08)' : 'transparent',
});

export const noteFieldTextStyles = {
  fontSize: '16px',
  lineHeight: '170%',
};

export const stagingBadgeStyles = (theme: Theme) => {
  return {
    '&:before': {
      zIndex: theme.zIndex.appBar + 100,
      background: theme.palette.warning.dark,
      content: '"Staging"',
      whiteSpace: 'nowrap',
      position: 'absolute',
      padding: '2px 20px',
      minWidth: '140px',
      color: 'white',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 'bold',
      boxSizing: 'border-box',
      borderBottomRightRadius: '5px',
      letterSpacing: 4,
    },
  };
};
