import { EOrder } from '@/types/EOrder';
import { ITableHeadCell } from '@/types/ITableHeadCell';
import { Box, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FC, MouseEvent } from 'react';
import { TableHeadCell } from './TableHeadCell';

interface ISortableTableHeadProps {
  cells: readonly ITableHeadCell[];
  order: EOrder;
  orderBy: string;
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
}
const SortableTableHead: FC<ISortableTableHeadProps> = (props) => {
  const { order, orderBy, onRequestSort, cells } = props;
  const createSortHandler = (property: string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableRow>
      {cells.map((cell) => (
        <TableHeadCell
          key={cell.id}
          sortDirection={orderBy === cell.id ? order : false}
          sx={{ py: 3.2, fontSize: '1rem', backgroundColor: '#fafafa', fontWeight: 600 }}
        >
          {cell.sortable ? (
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : EOrder.ASC}
              onClick={createSortHandler(cell.id)}
              sx={{ textDecoration: 'underline' }}
              className="dd-privacy-allow"
            >
              {cell.label}
              {orderBy === cell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            cell.label
          )}
        </TableHeadCell>
      ))}
    </TableRow>
  );
};

export default SortableTableHead;
