import { FC } from 'react';
import TableRowMessage from './TableRowMessage';

interface IProps {
  colSpan: number;
}
const TableRowLoading: FC<IProps> = ({ colSpan }) => (
  <TableRowMessage colSpan={colSpan} message="Loading cases..." />
);

export default TableRowLoading;
