import { DEFAULT_DATE_FORMAT } from '@/config/constants';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, TextField, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { FC, useState } from 'react';

interface IProps {
  defaultStartDate: Date | null;
  defaultEndDate: Date | null;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
}
const DateRangeFilter: FC<IProps> = (props) => {
  const { onChange, defaultStartDate, defaultEndDate } = props;

  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const handleRangeShortcut = (days: number) => {
    const today = new Date();
    const sDate = startOfDay(subDays(today, days));
    const eDate = endOfDay(today);
    setStartDate(sDate);
    setEndDate(eDate);
    onChange(sDate, eDate);
  };

  const handleStartDateChange = (date: Date) => {
    const sDate = startOfDay(date);
    const eDate = endDate != null ? endOfDay(endDate) : null;
    setStartDate(sDate);
    onChange(sDate, eDate);
  };
  const handleEndDateChange = (date: Date) => {
    const sDate = startDate != null ? startOfDay(startDate) : null;
    const eDate = endOfDay(date);
    setEndDate(eDate);
    onChange(sDate, eDate);
  };

  const handleRangeClearClick = () => {
    setStartDate(null);
    setEndDate(null);
    onChange(null, null);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          size="small"
          sx={{ whiteSpace: 'nowrap', mr: 1 }}
          onClick={() => handleRangeShortcut(7)}
          variant="outlined"
          className="dd-privacy-allow"
        >
          7 dage
        </Button>
        <Button
          size="small"
          sx={{ whiteSpace: 'nowrap' }}
          onClick={() => handleRangeShortcut(30)}
          variant="outlined"
          className="dd-privacy-allow"
        >
          30 dage
        </Button>
      </Box>

      <DatePicker
        label="Startdato"
        value={startDate}
        sx={(theme: Theme) => ({
          maxWidth: '165px',
          '& .MuiSvgIcon-root': { color: theme.palette.secondary.main },
        })}
        onChange={handleStartDateChange}
        slots={{
          textField: DatePickerTextField,
        }}
        format={DEFAULT_DATE_FORMAT}
      />
      <DatePicker
        label="Slutdato"
        value={endDate}
        sx={(theme: Theme) => ({
          maxWidth: '165px',
          '& .MuiSvgIcon-root': { color: theme.palette.secondary.main },
        })}
        onChange={handleEndDateChange}
        slots={{
          textField: DatePickerTextField,
        }}
        format={DEFAULT_DATE_FORMAT}
      />
      <IconButton
        aria-label="Ryd interval"
        onClick={handleRangeClearClick}
        sx={{
          color: grey[500],
          backgroundColor: grey[100],
          p: 0.8,
          '&:hover': {
            backgroundColor: grey[200],
          },
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  );
};

export default DateRangeFilter;

const DatePickerTextField = (props) => <TextField size="small" {...props} />;
