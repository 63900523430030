import { DEFAULT_CUSTOMER } from '@/config/constants';
import { EXMCaseStatusType } from '@/types/EXMCaseStatusType';
import { ICaseLocked } from '@/types/ICaseLocked';
import { ICode } from '@/types/ICode';
import { ISelectOption } from '@/types/ISelectOption';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';
import { sanitize } from 'isomorphic-dompurify';
import { formatAgo, formatDateTime, isLockStale } from './dates';

/**
 * Check whether the app is run inside an Electron wrapper.
 * @returns {boolean}
 */
export const isInsideElectron = () => {
  return window?.cortiCodeAPI != null;
};

/**
 * Return new modifiers or convert old modifiers (if any) to the new format.
 *
 * @param {ICode} code
 * @returns {ITerminologyCodeRead[]}
 */
export const getNewModifiers = (code: ICode): ITerminologyCodeRead[] => {
  if (code?.new_modifiers && code.new_modifiers.length > 0) {
    return code.new_modifiers;
  }

  return (
    code?.modifiers?.map(
      (modifier) => ({ name: modifier, description: modifier }) as ITerminologyCodeRead,
    ) || []
  );
};

/**
 * Return old modifiers or convert new modifiers (if any) to the old format.
 *
 * @param {ICode} code
 * @returns {string[]}
 */
export const getOldModifiers = (code: ICode): string[] => {
  if (code?.modifiers && code.modifiers.length > 0) {
    return code.modifiers;
  }

  return code?.new_modifiers?.map((modifier) => modifier.name) || [];
};

export const convertCaseUrgencyToXMStatusType = (isUrgent: boolean): EXMCaseStatusType => {
  return isUrgent ? EXMCaseStatusType.ACUTE : EXMCaseStatusType.DRAFT;
};

export const formatCaseLocked = (locked: ICaseLocked | null): string => {
  if (locked == null) {
    return null;
  }

  // if locked date is more than 48 hours ago, it's considered stale so return null
  const stale = isLockStale(locked.date);
  if (stale) {
    return null;
  }

  const lockedBy = locked.locked_by_me ? 'me' : sanitize(locked.name);
  const lockedAt = formatDateTime(locked.date);
  const lockedAgo = formatAgo(locked.date);

  return (
    'Locked by ' +
    lockedBy +
    ' at ' +
    lockedAt +
    (lockedAgo.length > 0 ? ' (' + lockedAgo + ')' : '')
  );
};

export const createSelectOption = (str: string): ISelectOption => ({
  label: str,
  value: str,
});

export const getCodeNameWithModifiers = (
  codeName: string,
  modifiers: ITerminologyCodeRead[] = [],
): string => {
  return (
    codeName +
    (modifiers && modifiers.length > 0
      ? ` + ${modifiers.map((modifier) => modifier.name).join(' + ')}`
      : '')
  );
};

export const getCodeWithModifiersDescription = (
  codeDescription: string,
  modifiers: ITerminologyCodeRead[] = [],
): string => {
  return (
    codeDescription +
    (modifiers && modifiers.length > 0
      ? ` + ${modifiers.map((modifier) => modifier.description).join(' + ')}`
      : '')
  );
};

export const getDoctorInitialsFromEmail = (email?: string) => {
  if (email == null) {
    return undefined;
  }

  const [initials] = email.split('@');
  if (initials == null) {
    return undefined;
  }

  return initials.toUpperCase();
};

export const getCustomerName = () => {
  const paramsCustomer = new URLSearchParams(window.location.search).get('customer');
  const localStorageCustomer = localStorage.getItem('customer');

  if (paramsCustomer && localStorageCustomer !== paramsCustomer) {
    localStorage.setItem('customer', paramsCustomer);
  }
  return paramsCustomer || localStorageCustomer || DEFAULT_CUSTOMER;
};

/**
 * Promisified setTimeout.
 *
 * @param delayMs
 * @returns
 */
export function delay(delayMs: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delayMs);
  });
}

/**
 * Returns a random integer number from the range.
 */
export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
