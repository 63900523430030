import useCases from '@/hooks/useCases';
import useUser from '@/hooks/useUser';
import { WavingHand } from '@mui/icons-material';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

export const WelcomeBox: FC = observer(() => {
  const cases = useCases();
  const user = useUser();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '160%',
          letterSpacing: '0.15px',
        }}
      >
        <WelcomeMessage username={user.name} />
      </Box>
      <Box
        sx={{
          mt: 0.6,
          fontSize: '16px',
          color: '#777777',
          maxWidth: '590px',
          wordBreak: 'break-all',
          textAlign: 'left',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%',
          letterSpacing: '0.15px',
        }}
      >
        {user.isSecretary() ? (
          <span className="dd-privacy-allow">
            Se markerede sager nedenfor. Røde sager er presserende og bør prioriteres.
          </span>
        ) : (
          <span className="dd-privacy-allow">
            Start Corti fra {cases?.config?.ehr_name} for at begynde dokumentation.
          </span>
        )}
      </Box>
    </Box>
  );
});

interface IWelcomeMessageProps {
  username?: string;
}
const WelcomeMessage: FC<IWelcomeMessageProps> = ({ username }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: '#233539',
    }}
  >
    <Box>Velkommen tilbage{username != null ? ', ' + username : ''}!</Box>
    <WavingHand />
  </Box>
);
