import {
  PATH_EDIT_CASE,
  PATH_INBOX_COMPLETED,
  PATH_NEW_CASE,
  PATH_NEW_CASE_FROM_XM,
  PATH_READONLY_CASE,
  PATH_SETTINGS,
} from '@/config/paths';
import useUserPreferences from '@/hooks/useUserPreferences';
import { isInsideElectron } from '@/utils/misc';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import WebNewCaseDialog from '../dialogs/web-only/WebNewCaseDialog';

interface ISitemapItem {
  path: string;
  parent: string | null;
  title: string;
}

const getHomeSitemapItem = (homePath: string): ISitemapItem => ({
  path: homePath,
  parent: null,
  title: 'Hjem',
});

/**
 * Get sitemap for the top navigation.
 * Please note, we cannot use the React Router routes here because we don't use inheritance there.
 *
 * @param homePath
 * @returns
 */
const getSitemap = (homePath: string): ISitemapItem[] => {
  return [
    getHomeSitemapItem(homePath),
    { path: PATH_INBOX_COMPLETED, parent: null, title: 'Hjem' },
    { path: PATH_NEW_CASE, parent: homePath, title: 'Ny sag' },
    { path: PATH_NEW_CASE_FROM_XM, parent: homePath, title: 'Ny sag' },
    { path: PATH_EDIT_CASE, parent: homePath, title: 'Rediger sag' },
    { path: PATH_READONLY_CASE, parent: homePath, title: 'Sag (Skrivebeskyttet)' },
    { path: PATH_SETTINGS, parent: homePath, title: 'Indstillinger' },
  ];
};

const TopNavbar = observer(() => {
  const location = useLocation();
  const userPreferences = useUserPreferences();

  const currentPath = location.pathname;
  const homePath = userPreferences.lastInboxPath;
  const sitemap = getSitemap(homePath);

  const currentItem =
    sitemap.find((item) => item.path === currentPath) || getHomeSitemapItem(homePath);
  const parentItem =
    currentItem?.parent != null ? sitemap.find((item) => item.path === currentItem.parent) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <NavLink
        to={currentItem.parent != null ? currentItem.parent : homePath}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '1rem',
          ...parentBreadcrumbItemStyles,
        }}
      >
        {currentItem.parent === null ? (
          <HomeIcon sx={backButtonStyles} />
        ) : (
          <ArrowBackIcon sx={backButtonStyles} />
        )}
      </NavLink>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '24px',
          lineHeight: '133.4%',
          textDecoration: 'none',
        }}
      >
        {parentItem !== null && (
          <NavLink
            style={parentBreadcrumbItemStyles}
            to={currentItem.parent}
            className="dd-privacy-allow"
          >
            {parentItem.title}
          </NavLink>
        )}
        {parentItem !== null && (
          <Box
            sx={{ ...parentBreadcrumbItemStyles, mx: 1.5, fontSize: '16px', fontWeight: 'bold' }}
          >
            &gt;
          </Box>
        )}
        {parentItem !== null ? (
          <Box className="dd-privacy-allow">{currentItem.title}</Box>
        ) : (
          <NavLink
            style={parentBreadcrumbItemStyles}
            to={currentItem.path}
            className="dd-privacy-allow"
          >
            {currentItem.title}
          </NavLink>
        )}
      </Box>

      <NewCaseControl />
    </Box>
  );
});

export default TopNavbar;

const backButtonStyles = {
  color: 'inherit',
  width: '2rem',
  height: '2rem',
};

const parentBreadcrumbItemStyles = {
  color: grey[600],
  textDecoration: 'none',
};

const NewCaseControl: FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelection = () => {
    navigate(PATH_NEW_CASE);
    handleClose();
  };

  if (isInsideElectron()) {
    return;
  }

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        sx={{
          ml: 2,
        }}
        onClick={handleOpen}
        className="dd-privacy-allow"
      >
        New
      </Button>
      <WebNewCaseDialog open={open} onClose={handleClose} onDone={handleSelection} />
    </>
  );
};
