import { getSetting } from '@/services/settings';
import { isInsideElectron } from '@/utils/misc';
import { FC, PropsWithChildren, useEffect } from 'react';

interface IProps {}
const SettingsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
  useEffect(() => {
    if (!isInsideElectron()) {
      return;
    }

    getSetting('customer').then((value: string | undefined) => {
      if (value == null && window.location.pathname !== '/settings') {
        window.location.replace('/settings');
      }
    });
  }, []);

  return <>{children}</>;
};

export default SettingsProvider;
