import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from '@/config/constants';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  intervalToDuration,
  parseISO,
  startOfDay,
} from 'date-fns';

export const formatDateWithDuration = (caseDate: string) => {
  if (caseDate == null) {
    return '';
  }

  const nowDate = new Date();
  const date = parseISO(caseDate);
  const dayDiff = differenceInDays(nowDate, startOfDay(date));
  const formattedDate = format(date, DEFAULT_DATETIME_FORMAT);
  return `${formattedDate} - ${dayDiff} day${dayDiff > 1 ? 's' : ''}`;
};

export const formatDateTime = (caseDate: string) => {
  const date = parseISO(caseDate);
  return format(date, DEFAULT_DATETIME_FORMAT);
};

export const formatDate = (caseDate: string) => {
  const date = parseISO(caseDate);
  return format(date, DEFAULT_DATE_FORMAT);
};

export const formatAgo = (caseDate: string) => {
  const date = parseISO(caseDate);
  const nowDate = new Date();

  const diffInDays = differenceInDays(nowDate, date);
  if (diffInDays > 0) {
    return diffInDays + 'd ago';
  }

  const diffInHours = differenceInHours(nowDate, date);
  if (diffInHours > 0) {
    return diffInHours + 'h ago';
  }

  const diffInMins = differenceInMinutes(nowDate, date);
  if (diffInMins > 0) {
    return diffInMins + 'm ago';
  }

  const diffInSecs = differenceInSeconds(nowDate, date);
  if (diffInSecs > 0) {
    return diffInSecs + 's ago';
  }

  return '';
};

export const isLockStale = (lockedDate: string): boolean => {
  const lockedDateObj = new Date(lockedDate);
  const now = new Date();
  const diff = now.getTime() - lockedDateObj.getTime();
  const hoursLimit = 48;
  return diff > hoursLimit * 60 * 60 * 1000;
};

export const formatDateToNumber = (caseDate: string): number => {
  if (caseDate == null) {
    return 0;
  }

  const date = parseISO(caseDate);
  return date.getTime();
};

export function formatAudioDurationS(durationInSeconds: number) {
  return formatAudioDurationMs(durationInSeconds * 1000);
}
export function formatAudioDurationMs(durationInMs: number) {
  const duration = intervalToDuration({ start: 0, end: isNaN(durationInMs) ? 0 : durationInMs });

  return [duration.minutes || 0, duration.seconds || 0].map(zeroPad).join(':');
}

function zeroPad(value: number | string) {
  return String(value).padStart(2, '0');
}
