import useCases from '@/hooks/useCases';
import { truncateString } from '@/utils/strings';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box } from '@mui/material';
import { red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import CaseDataBarItem from './CaseDataBarItem';

export const CaseFlagReason = observer(() => {
  const cases = useCases();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '560px',
      }}
    >
      <Box
        sx={{
          mr: 1,
          border: '1px solid ' + red[500],
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: red[50],
          color: red[500],
          borderRadius: '50%',
          width: '40px',
          height: '40px',
        }}
      >
        <PriorityHighIcon color="inherit" sx={{ width: '24px', height: '24px' }} />
      </Box>

      <CaseDataBarItem title="Bemærkninger" value={truncateString(cases.flagReason, 160)} />
    </Box>
  );
});
