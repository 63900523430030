import { Paper } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import TopNavbar from './TopNavbar';
import { cloudCards } from '@/utils/theme';

const TopBar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '80px',
        px: '1rem',
        borderRadius: 0,
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        gap: 6,
        ...cloudCards,
      }}
    >
      <TopNavbar />

      {children}
    </Paper>
  );
};

export default TopBar;
