import { IParsedCase } from '@/types/IParsedCase';
import { convertOldCategoryCodesToProcedure } from '@/utils/codes';
import { IXMedicusRequest } from './types';

export function parseRequest(request: IXMedicusRequest): IParsedCase {
  return {
    external_id: request.external_id,
    ehr_note: request.note,
    existing_codes: convertOldCategoryCodesToProcedure(request?.existing_codes || []),
    patient_id: request.person_id,
    cpr: request?.cpr || '',
    patient_firstname: request?.patient_firstname || '',
    patient_lastname: request?.patient_lastname || '',
    note_id: request.note_id,
    external_note_id: request.note_id,
    doctor_id: request?.doctor_id || '',
    doctor_initials: request?.doctor_initials || '',
    consultation_type: request?.treatment || '',
    specialty: request?.doctor_specialty || '',
    location: request?.location,
  };
}
