import { blinkStyles } from '@/utils/theme';
import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { green, blue, grey } from '@mui/material/colors';
import { formatAudioDurationS } from '@/utils/dates';

interface SpeechRecButtonProps {
  onClick: () => void;
  state: 'idle' | 'recording' | 'paused' | 'pausing';
}

export const SpeechRecButton: React.FC<SpeechRecButtonProps> = (props) => {
  const { onClick, state } = props;
  const color = state === 'recording' ? green : state === 'pausing' ? grey : blue;

  // styles
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={0}
    >
      <IconButton
        aria-label="record"
        onClick={onClick}
        data-testid="record-button"
        disabled={state === 'pausing'}
        sx={{
          backgroundColor: color[50],
          borderColor: color[600],
          color: color[600],
          px: '12px',
          py: '12px',
          borderWidth: 1,
          transition: 'all 50ms',
          borderStyle: 'solid',
          position: 'relative',
          '&:hover': {
            backgroundColor: color[100],
            borderColor: color[700],
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: state === 'recording' ? 'block' : 'none',
            borderRadius: '50%',
            transform: 'scale(var(--volume-scale))',
            background: color[500],
            opacity: 'var(--volume-opacity)',
            willChange: 'transform, opacity',
          },
        }}
      >
        {state === 'recording' ? (
          <PauseIcon
            sx={{
              ...blinkStyles,
              animation: 'blinker 1s linear infinite',
            }}
            width={20}
            height={20}
          />
        ) : state === 'pausing' ? (
          <CircularProgress size={24} />
        ) : (
          <MicIcon width={20} height={20} />
        )}
      </IconButton>
    </Box>
  );
};
