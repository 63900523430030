import { isStaging } from '@/utils/env';
import { flexColumnFullHeight, stagingBadgeStyles } from '@/utils/theme';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import TopBar from './TobBar';

interface IProps {
  topBarContent?: ReactNode;
}

const MainLayout: FC<PropsWithChildren<IProps>> = ({ children, topBarContent }) => {
  const theme = useTheme();

  let containerStyles: SxProps<Theme> = {
    ...flexColumnFullHeight,
  };

  if (isStaging()) {
    containerStyles = {
      ...containerStyles,
      ...stagingBadgeStyles(theme),
    };
  }

  return (
    <Box sx={containerStyles}>
      <TopBar>{topBarContent}</TopBar>

      {children}
    </Box>
  );
};

export default MainLayout;
