import { cloudCards } from '@/utils/theme';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, MouseEvent, PropsWithChildren } from 'react';

interface IProps {
  title: string;
  description?: string;
  cancelActionLabel?: string;
  acceptActionLabel?: string;
  open: boolean;
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void;
  onAccept?: (e: MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
}
const ConfirmDialog: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    open,
    onCancel,
    onAccept,
    title,
    cancelActionLabel = 'Annuller',
    acceptActionLabel = 'Fortsæt',
    isLoading,
    children,
  } = props;
  return (
    <Dialog
      open={open}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
        '.MuiDialog-paper': {
          ...cloudCards,

          width: '100%',
          maxWidth: '640px',
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions sx={{ px: '24px', py: '16px' }}>
        {onAccept && (
          <Button
            color="success"
            variant="contained"
            onClick={onAccept}
            disabled={isLoading}
            className="dd-privacy-allow"
          >
            {acceptActionLabel}
          </Button>
        )}
        {onCancel && (
          <Button
            onClick={onCancel}
            autoFocus
            variant="outlined"
            color="info"
            className="dd-privacy-allow"
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          >
            {cancelActionLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
