export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL;
export const DEFAULT_CUSTOMER = import.meta.env.VITE_DEFAULT_CUSTOMER || 'code';
export const MODE = import.meta.env.MODE;
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;

// Used to more finely define where the production app is deployed
// Meant to be set on container startup to e.g. staging or production
export const DEPLOYMENT_ENV = import.meta.env.VITE_DEPLOYMENT_ENV;

// @todo: Replace it with a dynamic backend customer list.
export const KNOWN_CUSTOMERS = ['capio', 'code', 'test'];

// @todo: Replace it with a dynamic backend customer-specific settings.
export const DEFAULT_CODE_MODIFIERS: any[] = [
  {
    name: 'TUL1',
    description: 'højresidig',
  },
  {
    name: 'TUL2',
    description: 'venstresidig',
  },
  {
    name: 'TUL3',
    description: 'dobbeltsidig',
  },
];

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATETIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export const ROLE_SECRETARY = 'secretary';
export const ROLE_ADMIN = 'admin';
export const ROLE_DOCTOR = 'doctor';
export const ROLE_ALPHA_TESTER = 'code-alpha-tester';
export const ROLE_BETA_TESTER = 'code-beta-tester';
export const ROLE_LLM_DOCTOR = 'llm-doctor';

export const TABLE_REFRESH_TIMEOUT = 2 * 60 * 1000; // 2 mins.

export const CODE_CATEGORY_MODIFIER = 'modifier';
export const CODE_CATEGORY_DIAGNOSIS = 'diagnosis';
export const CODE_CATEGORY_DIAGNOSIS_OLD = 'diagnose';
export const CODE_CATEGORY_PROCEDURE = 'procedure';
export const CODE_CATEGORIES = [CODE_CATEGORY_DIAGNOSIS, CODE_CATEGORY_PROCEDURE];
export const CODE_CATEGORIES_OLD_PROCEDURE = ['operation', 'treatment'];

export const DEFAULT_PLAYBACK_SPEED = 1;

export const AUDIO_STORING_RETRY_ERROR_STATUS = 502;
export const AUDIO_STORING_RETRY_ATTEMPTS_NUM = 2;

export const CAPIO_CUSTOMER_NAME = 'capio';
export const CAPIO_FLAGGED_NOTE_PREFIX =
  'Dette notat er en kladde og er endnu ikke korrigeret af en lægesekretær.';
