import { ESegmentTranscriptionStatus } from '@/types/ESegmentTranscriptionStatus';
import { ITranscriptionResponse } from '@/types/ITranscriptionResponse';
import { appendNoteCorrected, appendNoteStructured } from '@/utils/note';

class AudioSegmentTranscriptionManager {
  private segmentResponseMap: Map<number, ISegment> = new Map();
  private lastSegmentId: number = 0;

  public getLastSegmentId = (): number => this.lastSegmentId;
  public getSegmentResponseMap = (): Map<number, ISegment> => this.segmentResponseMap;

  public nextSegmentId = (): number => {
    const nextId = this.lastSegmentId + 1;
    this.segmentResponseMap.set(nextId, {
      segment: null,
      status: ESegmentTranscriptionStatus.NEW,
    });
    this.lastSegmentId = nextId;
    return nextId;
  };

  public isAudioProcessedFully = (): boolean => {
    for (let [_, segment] of this.segmentResponseMap) {
      if (
        ![ESegmentTranscriptionStatus.COMPLETED, ESegmentTranscriptionStatus.FAILED].includes(
          segment.status,
        )
      ) {
        return false;
      }
    }
    return true;
  };

  public registerReceivedSegmentTranscription = (
    id: number,
    segmentResponse: ITranscriptionResponse,
  ) => {
    this.setSegmentTranscription(
      id,
      segmentResponse,
      ESegmentTranscriptionStatus.TRANSCRIPTION_RECEIVED,
    );
    console.debug('debug: transcription received', segmentResponse);
    console.debug('debug: current segment map', this.segmentResponseMap);
  };

  public registerFailedSegmentTranscription = (id: number) => {
    this.setSegmentTranscription(id, null, ESegmentTranscriptionStatus.FAILED);
    console.debug('debug: transcription failed', id);
    console.debug('debug: current segment map', this.segmentResponseMap);
  };

  public getCurrentTranscription = (): ITranscriptionResponse | undefined => {
    let firstReadySegmentId = undefined;
    for (let [index, segment] of this.segmentResponseMap) {
      if (segment.status === ESegmentTranscriptionStatus.NEW) {
        break;
      }

      if (segment.status === ESegmentTranscriptionStatus.TRANSCRIPTION_RECEIVED) {
        firstReadySegmentId = index;
        break;
      }
    }

    if (firstReadySegmentId == null) {
      return undefined;
    }

    let index = firstReadySegmentId;
    let combinedTranscription: ITranscriptionResponse = undefined;
    while (index <= this.lastSegmentId) {
      const segment = this.segmentResponseMap.get(index);

      if (segment.status === ESegmentTranscriptionStatus.FAILED) {
        index++;
        continue;
      }

      if (segment.status !== ESegmentTranscriptionStatus.TRANSCRIPTION_RECEIVED) {
        break;
      }

      combinedTranscription = {
        note_structured: appendNoteStructured(
          combinedTranscription?.note_structured,
          segment.segment?.note_structured,
        ),
        note_corrected: appendNoteCorrected(
          combinedTranscription?.note_corrected,
          segment.segment?.note_corrected,
        ),
      };

      this.segmentResponseMap.set(index, {
        ...segment,
        status: ESegmentTranscriptionStatus.COMPLETED,
      });

      index++;
    }

    console.debug('debug: combined transcription generated', combinedTranscription);
    console.debug('debug: current segment map', this.segmentResponseMap);
    return combinedTranscription;
  };

  private setSegmentTranscription = (
    id: number,
    segmentResponse: ITranscriptionResponse | null,
    status: ESegmentTranscriptionStatus,
  ) => {
    this.segmentResponseMap.set(id, {
      segment: segmentResponse,
      status,
    });
  };
}

export default AudioSegmentTranscriptionManager;

interface ISegment {
  segment: ITranscriptionResponse | null;
  status: ESegmentTranscriptionStatus;
}
