import LockFlag from '@/components/case/LockFlag';
import InboxTopBarContent from '@/components/inbox/InboxTobBarContent';
import { ContentWrapper } from '@/components/layout/ContentWrapper';
import MainLayout from '@/components/layout/MainLayout';
import SortableTableHead from '@/components/tables/SortableTableHead';
import TableHeadFilters from '@/components/tables/TableHeadFilters';
import TableRowLoading from '@/components/tables/TableRowLoading';
import TableRowNoCasesFound from '@/components/tables/TableRowNoCasesFound';
import { TABLE_REFRESH_TIMEOUT } from '@/config/constants';
import { PATH_EDIT_CASE, PATH_INBOX } from '@/config/paths';
import useAlerts from '@/hooks/useAlerts';
import useCases from '@/hooks/useCases';
import useFetchAllCodes from '@/hooks/useFetchAllCodes';
import useUserPreferences from '@/hooks/useUserPreferences';
import useWindowSize from '@/hooks/useWindowSize';
import { getFlaggedCases, getNewCases } from '@/services/BackendAPI';
import { requestPedals } from '@/services/pedals';
import { EOrder } from '@/types/EOrder';
import { ISelectOption } from '@/types/ISelectOption';
import { ITableCase } from '@/types/ITableCase';
import { ITableFilterOptions } from '@/types/ITableFilterOptions';
import { ITableHeadCell } from '@/types/ITableHeadCell';
import { createSortComparatorFn } from '@/utils/sort';
import {
  addFilterValueToFilters,
  collectFilterValues,
  createSearchFilterFn,
  mapReceivedCaseToTableCase,
} from '@/utils/tables';
import { cardContainerStyles, flexColumnFullHeight, urgentCaseTableRowStyles } from '@/utils/theme';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const headCells: readonly ITableHeadCell[] = [
  {
    id: 'created_at',
    label: 'Dato',
    sortable: true,
  },
  {
    id: 'patient_id',
    label: 'Patient ID',
  },
  {
    id: 'external_id',
    label: 'Journal ID',
  },
  {
    id: 'doctor_initials',
    label: 'Læge',
    sortable: true,
  },
  {
    id: 'flag_reason',
    label: 'Bemærkninger',
  },
  {
    id: 'consultation_type',
    label: 'Konsultationstype',
  },
  {
    id: 'specialty',
    label: 'Speciale',
  },
  {
    id: 'location',
    label: 'Lokation',
  },
  {
    id: 'locked',
    label: 'Låst',
  },
];

const defaultFilterOptions: ITableFilterOptions = {
  location: [],
  specialty: [],
  doctor_initials: [],
  consultation_type: [],
};

export const InboxPage = observer(() => {
  const cases = useCases();
  useFetchAllCodes(cases.config?.code_systems);
  const alerts = useAlerts();
  const userPreferences = useUserPreferences();
  const navigate = useNavigate();
  const size = useWindowSize();
  const [filterOptions, setFilterOptions] = useState<ITableFilterOptions>(defaultFilterOptions);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRowClick = async (caseId: number, isDraft: boolean) => {
    cases.fetchCase(caseId, isDraft, () => {
      cases.enableCodesEditMode();
      cases.loadFullAudio();
    });
    await requestPedals();
    navigate(PATH_EDIT_CASE);
  };

  const handleFilterChange = (field: string, selectedOption: ISelectOption) => {
    const newFilters = addFilterValueToFilters(
      userPreferences.flaggedCasesFilters,
      selectedOption,
      field,
    );
    userPreferences.setFlaggedCasesFilters(newFilters);
  };

  const handleSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc =
      userPreferences.flaggedCasesOrder?.orderBy === property &&
      userPreferences.flaggedCasesOrder?.order === EOrder.ASC;
    userPreferences.setFlaggedCasesOrder(property, isAsc ? EOrder.DESC : EOrder.ASC);
  };

  const handleDateFilterChange = (filterStartDate: Date | null, filterEndDate: Date | null) => {
    userPreferences.setFlaggedCasesFilters({ from_date: filterStartDate, to_date: filterEndDate });
  };

  const handleSearch = (searchPhrase: string) => {
    userPreferences.setFlaggedCasesFilters({ query: searchPhrase });
  };

  const sortedAndFilteredCases = useMemo(() => {
    // Map cases data to table fields.
    const mappedFlaggedCases =
      cases.flaggedCases?.map((data) => mapReceivedCaseToTableCase(data, false)) || [];

    // Map NEW cases to table fields
    const mappedNewCases =
      cases.newCases?.map((data) => mapReceivedCaseToTableCase(data, true)) || [];

    // Merge new cases with flagged cases
    const combinedNewAndFlagged = userPreferences.showDrafts
      ? mappedNewCases.concat(mappedFlaggedCases)
      : mappedFlaggedCases;

    // Collect values for filters.
    const options = collectFilterValues(combinedNewAndFlagged, defaultFilterOptions);
    setFilterOptions(options);

    // Filter and sort.
    return combinedNewAndFlagged
      .filter(createSearchFilterFn(userPreferences.flaggedCasesFilters))
      .sort(
        createSortComparatorFn(
          userPreferences.flaggedCasesOrder?.order,
          userPreferences.flaggedCasesOrder?.orderBy,
        ) as (a: any, b: any) => number,
      )
      .sort(createSortComparatorFn(EOrder.DESC, 'urgent') as (a: any, b: any) => number)
      .sort(createSortComparatorFn(EOrder.DESC, 'isNew') as (a: any, b: any) => number);
  }, [
    cases.flaggedCases,
    cases.newCases,
    userPreferences.flaggedCasesFilters,
    userPreferences.flaggedCasesOrder?.order,
    userPreferences.flaggedCasesOrder?.orderBy,
    userPreferences.showDrafts,
  ]);

  const windowHeightWithoutHeader = size.height - 80;

  const loadCases = (
    indicateLoading: boolean = true,
    startDate: Date | null,
    endDate: Date | null,
    query: string | null,
  ) => {
    if (indicateLoading) {
      setLoading(true);
    }

    Promise.all([
      getNewCases(startDate, endDate, query),
      getFlaggedCases(startDate, endDate, query),
    ])
      .then(([newCases, flaggedCases]) => {
        cases.setNewCases(newCases);
        cases.setFlaggedCases(flaggedCases);
      })
      .catch((e) => {
        console.error(e);
        alerts.error(
          'We cannot load the cases at the moment. Please try again or contact the administrator',
        );
      })
      .finally(() => {
        if (indicateLoading) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    userPreferences.setLastInboxPath(PATH_INBOX);

    const startDate = userPreferences.flaggedCasesFilters?.from_date || null;
    const endDate = userPreferences.flaggedCasesFilters?.to_date || null;
    const query = userPreferences.flaggedCasesFilters?.query || null;

    // Load cases on page load and display loading indicator.
    loadCases(true, startDate, endDate, query);

    // Load cases regularly.
    const intervalId = setInterval(
      () => loadCases(false, startDate, endDate, query),
      TABLE_REFRESH_TIMEOUT,
    );
    return () => clearInterval(intervalId);
  }, [
    userPreferences.flaggedCasesFilters?.from_date,
    userPreferences.flaggedCasesFilters?.to_date,
    userPreferences.flaggedCasesFilters?.query,
  ]);

  return (
    <MainLayout topBarContent={<InboxTopBarContent />}>
      <ContentWrapper
        wrapperSx={{
          ...flexColumnFullHeight,
          height: 'auto',
          maxHeight: windowHeightWithoutHeader,
        }}
        sx={flexColumnFullHeight}
      >
        <TableContainer
          sx={{
            ...cardContainerStyles,
            width: 'auto',
            p: 0,
          }}
        >
          <Table aria-label="Cases for review" sx={{ p: 1, tableLayout: 'auto' }}>
            <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
              <TableHeadFilters
                colSpan={headCells.length}
                handleSearch={handleSearch}
                handleFilterChange={handleFilterChange}
                handleDateFilterChange={handleDateFilterChange}
                filterOptions={filterOptions}
                filterValues={userPreferences.flaggedCasesFilters}
                rowCount={sortedAndFilteredCases.length}
              />
              <SortableTableHead
                cells={headCells}
                order={userPreferences.flaggedCasesOrder?.order}
                orderBy={userPreferences.flaggedCasesOrder?.orderBy}
                onRequestSort={handleSort}
              />
            </TableHead>
            <TableBody>
              {loading && <TableRowLoading colSpan={headCells.length} />}
              {!loading && sortedAndFilteredCases.length === 0 && (
                <TableRowNoCasesFound colSpan={headCells.length} />
              )}
              {sortedAndFilteredCases.map((tableCase: ITableCase) => (
                <TableRow
                  hover
                  key={tableCase.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    ...urgentCaseTableRowStyles(tableCase.urgent),
                    ...(tableCase.isNew && { backgroundColor: 'rgba(254, 215, 170, 0.8)' }),
                  }}
                  onClick={() => {
                    handleRowClick(tableCase.id, tableCase.isNew);
                  }}
                >
                  <TableCell sx={{ width: '280px' }}>
                    {tableCase.created_at_formatted || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '140px' }}>{tableCase.patient_id || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '140px' }}>{tableCase.external_id || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '140px' }}>
                    {tableCase.doctor_initials || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ minWidth: '320px', wordBreak: 'break-word' }}>
                    {tableCase.flag_reason || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '250px' }}>
                    {tableCase.consultation_type || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: '250px' }}>{tableCase.specialty || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '250px' }}>{tableCase.location || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '100px' }}>
                    {tableCase.locked && (
                      <LockFlag
                        lockedDetails={tableCase.locked}
                        lockedByMe={tableCase.locked_by_me}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentWrapper>
    </MainLayout>
  );
});
