import { isInsideElectron } from '@/utils/misc';

export const SUPPORTED_PEDAL_DEVICES: HIDDeviceFilter[] = [
  { vendorId: 1972, productId: 536 },
  { vendorId: 1972, productId: 659 },
  { vendorId: 1972, productId: 622 },
];
/**
 * Because the button-release event (key up) does not contain the command,
 * we cannot determine if a key is being long=pressed or not.
 * Therefore, we need to keep track of the number of commands that have been sent but not yet closed.
 * This is done by incrementing the counter when a command is sent,
 * and decrementing it when a button is released.
 * When the counter is 0, no commands are being sent.
 * Given we only need to support long-presses for the play/pause button,
 * we can also keep track of whether the play command is still open.
 * This is done by setting the playCmdUnclosed flag to true when the play command is sent,
 * and setting it to false when the play button is released.
 * When the counter is 1 and the playCmdUnclosed flag is true, we know that the play button is being long-pressed,
 * so when cmd 0 is sent, we can assume that the play button has been released.
 *
 * @param inputReportHandler
 */

let allDevices = [];

export async function activatePedals(inputReportHandler: (event: HIDInputReportEvent) => void) {
  allDevices.forEach(async (device) => {
    if (!isHidDeviceSupported(device)) {
      return;
    }

    device.addEventListener('inputreport', inputReportHandler);
    !device.opened && (await device.open());
  });
}

export async function requestPedals() {
  if (allDevices.length > 0 || !isInsideElectron()) return;
  if ('hid' in navigator) {
    navigator.hid.addEventListener('disconnect', ({ device }) => {
      console.log('Device disconnected:', device);
      allDevices = allDevices.filter(
        (d) => d.productId !== device.productId && d.vendorId !== device.vendorId,
      );
    });

    navigator.hid.addEventListener('connect', ({ device }) => {
      console.log('Device connected:', device);
      requestPedals();
    });

    try {
      const devices = await navigator.hid.requestDevice({
        filters: SUPPORTED_PEDAL_DEVICES,
      });
      if (devices.length === 0) {
        console.log('No devices found');
        return;
      }
      for (const device of devices) {
        console.log(device);
        if (!device.opened) {
          await device.open();
          allDevices.push(device);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  } else {
    console.error('WebHID API is not supported');
  }
}

/**
 * Close all pedal devices and remove event listeners.
 *
 * @param inputReportHandler
 */
export async function releasePedals(inputReportHandler: (event: HIDInputReportEvent) => void) {
  allDevices.forEach(async (device) => {
    if (!isHidDeviceSupported(device) || !device.opened) {
      return;
    }

    device.removeEventListener('inputreport', inputReportHandler);
  });
}

const isHidDeviceSupported = (device: HIDDeviceFilter) => {
  return SUPPORTED_PEDAL_DEVICES.some(
    (d: HIDDeviceFilter) => d.productId === device.productId && d.vendorId === device.vendorId,
  );
};
