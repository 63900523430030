import React, { forwardRef } from 'react';
import {
  MDXEditor,
  listsPlugin,
  thematicBreakPlugin,
  quotePlugin,
  codeBlockPlugin,
  directivesPlugin,
  AdmonitionDirectiveDescriptor,
  toolbarPlugin,
  MDXEditorMethods,
} from '@mdxeditor/editor';
import { Box } from '@mui/material';
import { TemplateSearchBar } from './templates/TemplateSearch';

interface EditorComponentProps {
  note: string;
  handleEditorChanges: (markdown: string) => void;
  handleInsertTemplate: (templateText: string) => void;
  readOnly: boolean;
}

// Use forwardRef and assign the props type
const EditorComponent = forwardRef<MDXEditorMethods, EditorComponentProps>(
  ({ note, handleEditorChanges, handleInsertTemplate, readOnly }, ref) => (
    <MDXEditor
      markdown={note || '&#x20;'}
      readOnly={readOnly}
      ref={ref}
      onChange={handleEditorChanges}
      contentEditableClassName="contentEditable"
      className="mdxeditor"
      plugins={[
        listsPlugin(),
        thematicBreakPlugin(),
        quotePlugin(),
        codeBlockPlugin(),
        directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
        toolbarPlugin({
          toolbarContents: () => (
            <Box display="flex">
              <TemplateSearchBar handleInsertTemplate={handleInsertTemplate} />
            </Box>
          ),
        }),
      ]}
    />
  ),
);

export default EditorComponent;
