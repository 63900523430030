import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { debounce, IconButton, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import { ChangeEvent, FC, MouseEvent, useCallback, useRef, useState } from 'react';

const DEBOUNCE_TIMEOUT = 200;

interface IProps {
  defaultValue?: string;
  onSearch: (searchPhrase: string) => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
}
const DebouncedSearchBar: FC<IProps> = (props) => {
  const { onSearch, placeholder, sx, defaultValue = '' } = props;
  const [searchPhrase, setSearchPhrase] = useState<string>(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onSearch(e.target.value);
    setSearchPhrase(e.target.value);
  };

  const handleSearchDebounced = useCallback(debounce(handleSearch, DEBOUNCE_TIMEOUT), [
    searchPhrase,
  ]);

  const handleClearClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    inputRef.current.value = '';
    setSearchPhrase('');
    onSearch('');
  };

  return (
    <TextField
      defaultValue={defaultValue}
      sx={{
        '& .MuiInputBase-root': { px: 0.5 },
        ...sx,
      }}
      inputRef={inputRef}
      type="text"
      onChange={handleSearchDebounced}
      placeholder={placeholder ? placeholder : 'Search...'}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mx: 0.5 }}>
            <SearchIcon color="secondary" />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            sx={{ visibility: searchPhrase ? 'visible' : 'hidden' }}
            onClick={handleClearClick}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default DebouncedSearchBar;
