import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const CodeTypeHeader: FC<PropsWithChildren> = ({ children }) => (
  <Typography
    variant="h6"
    sx={{
      mb: 0.5,
      textTransform: 'capitalize',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      opacity: 0.54,
      alignItems: 'center',
    }}
  >
    {children}
  </Typography>
);

export default CodeTypeHeader;
