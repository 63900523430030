import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { Link, useRouteError } from 'react-router-dom';
import Logo from '../assets/images/logo.svg?react';

export const ErrorPage = observer(() => {
  let error = useRouteError();
  console.error(error);
  // @todo: Report the error to an error console instead of displaying it on the screen.

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          minHeight: '100vh',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: 3,
          py: 2,
        }}
      >
        <Link to={'/'}>
          <Logo width="100" height="100" />
        </Link>
        <Box sx={{ mt: 5 }}>An error has occurred. Please contact the administrator.</Box>
        <Box sx={{ mt: 10, color: grey[600] }}>Error log:</Box>
        <Box
          sx={{
            mt: 1,
            overflowY: 'scroll',
            maxHeight: '150px',
            maxWidth: '700px',
            border: 1,
            borderRadius: 2,
            borderColor: grey[300],
            p: 2,
            color: grey[600],
          }}
        >
          {JSON.stringify(error, null, 2)}
        </Box>
      </Box>
    </Box>
  );
});
