import { EOrder } from '@/types/EOrder';

/**
 * Sort callback which is used for comparing objects by a key.
 *
 * @param order
 * @param orderBy
 * @returns
 */
export function createSortComparatorFn<Key extends keyof any>(
  order: EOrder,
  orderBy: Key | undefined,
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean },
) => number {
  return order === EOrder.DESC
    ? (a, b) => descendingSortComparator(a, b, orderBy)
    : (a, b) => -descendingSortComparator(a, b, orderBy);
}

function descendingSortComparator<T>(a: T, b: T, orderBy: keyof T | undefined) {
  // If the orderBy key is undefined (sorting criterion is not set),
  // we don't reorder items.
  if (orderBy == null) {
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
