import { CodeSystemModelType, db } from '@/services/IndexedDB';
import { ITerminologyCodeRead } from '@/types/ITerminologyCodeRead';

export const getCodeSystem = async (codeSystemName) =>
  await db.codeSystems.where('name').equals(codeSystemName).sortBy('updatedAt');

export const getCodeSystems = async (
  codeSystems: string[],
): Promise<{ [name: string]: CodeSystemModelType }> => {
  const availableCodeSystems: { [name: string]: CodeSystemModelType } = {};
  for (const codeSystemName of codeSystems) {
    const sortedCodeSystemRows = await getCodeSystem(codeSystemName);
    if (sortedCodeSystemRows && sortedCodeSystemRows.length > 0) {
      availableCodeSystems[codeSystemName] = sortedCodeSystemRows.pop()!;
    }
  }
  return availableCodeSystems;
};

export const storeCodes = async (name: string, codes: ITerminologyCodeRead[]) =>
  await db.codeSystems.add({
    name,
    updatedAt: new Date().getTime(),
    codes,
  });

export const deleteOldCodes = async (name: string, cachingThreshold: number) =>
  await db.codeSystems
    .where('name')
    .equals(name)
    .and((codeSystem) => codeSystem.updatedAt < new Date().getTime() - cachingThreshold) // cleanup the previously cached codes
    .delete();
