import { TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

interface IProps {
  colSpan: number;
  message: string;
}
const TableRowMessage: FC<IProps> = ({ colSpan, message }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell colSpan={colSpan}>{message}</TableCell>
    </TableRow>
  );
};

export default TableRowMessage;
