import { titleStyles } from '@/utils/theme';
import { ThemeOptions } from '@mui/material';
import { blue, grey } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options.
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
  }

  // Allow configuration using `createTheme`.
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options.
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}

const themeSettings: ThemeOptions = {
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    title: titleStyles,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
  },
  // components: {
  //   MuiTypography: {
  //     defaultProps: {
  //       variantMapping: {
  //         // Map the new variant to render a <h1> by default.
  //         title: 'h1',
  //       },
  //     },
  //   },
  // },
  palette: {
    success: {
      main: '#4CAF50',
      contrastText: '#fff',
    },
    warning: {
      main: '#F44336',
      contrastText: '#fff',
    },
    info: {
      main: '#FF9800',
    },
    primary: grey,
    secondary: {
      main: '#4192EC',
      light: blue[100],
    },
    neutral: {
      main: grey[50],
    },
  },
};

export default themeSettings;
