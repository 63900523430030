import { CardContainer } from '@/components/layout/CardContainer';
import { ContentWrapper } from '@/components/layout/ContentWrapper';
import MainLayout from '@/components/layout/MainLayout';
import { KNOWN_CUSTOMERS } from '@/config/constants';
import useAlerts from '@/hooks/useAlerts';
import { getSetting, setSetting } from '@/services/settings';
import { flexColumnFullHeight } from '@/utils/theme';
import { Box, Button, TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

export const SettingsPage = () => {
  const [customer, setCustomer] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const alertContext = useAlerts();

  const handleSave = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // @todo: Run this check against the backend customer list instead.
    if (!KNOWN_CUSTOMERS.includes(customer)) {
      setError('Please check that the customer name entered is correct.');
      return;
    }

    try {
      await setSetting('customer', customer);
      alertContext.success('The settings have been saved successfully');
      window.location.replace(`/?customer=${customer}`);
    } catch (e) {
      alertContext.error('Could not load the settings. Please contact the administrator.');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error != null) {
      setError(null);
    }
    setCustomer(e.target.value.trim());
  };

  useEffect(() => {
    getSetting('customer')
      .then((value: string | null) => {
        if (value == null) {
          value = '';
        }
        setCustomer(value);
      })
      .catch((e) => {
        // @todo: Report an error to us.
        console.error(e);
        alertContext.error('Could not load the settings. Please contact the administrator.');
      });
  }, []);

  return (
    <MainLayout>
      <ContentWrapper wrapperSx={flexColumnFullHeight} sx={flexColumnFullHeight}>
        <CardContainer title="Customer">
          <Box component="form" sx={{ p: 1 }}>
            <TextField
              name="customer"
              label="Customer name"
              value={customer}
              onChange={handleChange}
              required
            />
            {error && <Box sx={{ fontSize: '12px', color: red[400], mt: 1 }}>{error}</Box>}
            <Button
              variant="contained"
              size="large"
              color="success"
              onClick={handleSave}
              sx={{ display: 'block', mt: 2 }}
              className="dd-privacy-allow"
            >
              Save
            </Button>
          </Box>
        </CardContainer>
      </ContentWrapper>
    </MainLayout>
  );
};
