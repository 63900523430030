/**
 * Focus on the textfield in 100ms after rendering.
 * This is to fix the autofocus issue of the MUI TextField component.
 *
 * @param input
 */
export const autoFocusedFieldRef = (input: any) => {
  if (input) {
    setTimeout(() => {
      input.focus();
    }, 100);
  }
};
