import { Box, Chip, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { feedbackProvided } from '@/services/analytics';

interface FeedbackGetterProps {
  caseId: number;
}

export const FeedbackGetter = ({ caseId }: FeedbackGetterProps) => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (feedback) => {
    const scale = 5;
    feedbackProvided(caseId, feedback, scale);
    setSubmitted(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: 2,
        border: '1px solid #ccc',
        borderRadius: '12px',
        backgroundColor: '#ffffff50',
        mt: 1,
      }}
    >
      <Chip color="secondary" label="Giv din mening" variant="outlined" />
      {submitted ? (
        <Typography variant={'h5'}>Tak for din feedback!</Typography>
      ) : (
        <>
          <Typography variant={'h5'}>Hvordan var kvaliteten af transkriptionen?</Typography>
          <Box display="grid" gridTemplateColumns="1fr auto 1fr" alignItems="center" gap={1}>
            <Typography variant="subtitle1" textAlign="right">
              Dårlig 👎
            </Typography>
            <ToggleButtonGroup
              exclusive
              sx={{
                backgroundColor: '#ffffff',
              }}
              size={'large'}
              value={null}
              onChange={(_event, feedback) => handleSubmit(feedback)}
            >
              <ToggleButton value={1}>1</ToggleButton>
              <ToggleButton value={2}>2</ToggleButton>
              <ToggleButton value={3}>3</ToggleButton>
              <ToggleButton value={4}>4</ToggleButton>
              <ToggleButton value={5}>5</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="subtitle1">👍 Fremragende</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
