import {
  CODE_CATEGORIES_OLD_PROCEDURE,
  CODE_CATEGORY_DIAGNOSIS,
  CODE_CATEGORY_DIAGNOSIS_OLD,
  CODE_CATEGORY_PROCEDURE,
} from '@/config/constants';
import { ICode } from '@/types/ICode';
import random from 'random-string-generator';
import { getNewModifiers } from './misc';

export const hasDuplicateCodesShallow = (codes: ICode[] = []) => {
  const uniqueCodes = codes.reduce((uniqueCodesTemp, currentCode) => {
    const isDuplicate = uniqueCodesTemp.some((code: ICode) => {
      // If code names are different, then they are definitely not duplicates.
      if (currentCode.name !== code.name) {
        return false;
      }

      // @todo: Also compare modifiers.

      return true;
    });
    if (!isDuplicate) {
      uniqueCodesTemp.push(currentCode);
    }
    return uniqueCodesTemp;
  }, []);

  // If the number of unique codes lower than the number of original codes,
  // we make the conclusion that there are duplicates in the original array.
  return uniqueCodes.length < codes.length;
};

export const codeSortHandler = (a: ICode, b: ICode) => {
  let x = a.type.toLowerCase();
  let y = b.type.toLowerCase();
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
};

/**
 * Return a string like [code name][modifier name 1]...[modifier name N].
 *
 * @param code
 * @returns
 */
export const codeNameWithModifiers = (code: ICode): string => {
  return (
    code.name +
    getNewModifiers(code)
      .map((modifier) => modifier.name)
      .join('')
  );
};

/**
 * Return a string like [code name][modifier name 1]...[modifier name N]
 * where modifiers are sorted alphabetically.
 *
 * @param code
 * @returns
 */
export const codeNameWithSortedModifiers = (code: ICode): string => {
  return (
    code.name +
    getNewModifiers(code)
      .map((modifier) => modifier.name)
      .sort((a, b) => a.localeCompare(b))
      .join('')
  );
};

/**
 * Generate a unique string based on the code name, modifiers names and a random string.
 *
 * @param code
 * @returns
 */
export const generateInternalCodeId = (code: ICode) => {
  // 8 random symbols combined with code name and modifier names should be more than enough to guaranty unique IDs,
  // because in that case the competition will be just between 2-5 codes max.
  return codeNameWithModifiers(code) + random(8);
};

/**
 * Index the code.
 * We assign a unique _internalID to the code if it doesn't have it yet.
 *
 * @param code
 * @returns
 */
export const indexCode = (code: ICode): ICode => {
  if (code._internalId != null) {
    return code;
  }

  return cloneCode(code);
};

/**
 * Assign unique _internalId-s to codes which don't have it set yet.
 *
 * @param codes
 * @returns
 */
export const indexCodes = (codes: ICode[]) => {
  return codes?.map(indexCode) || [];
};

/**
 * Clone the code, assigning it a new _internalID.
 * @param code
 * @returns
 */
export const cloneCode = (code: ICode): ICode => {
  return {
    ...code,
    _internalId: generateInternalCodeId(code),
  };
};

/**
 * Replace old categories with the new one in the given list of codes.
 *
 * @param codes
 * @returns
 */
export const convertOldCategoryCodesToProcedure = (codes: ICode[]) => {
  return codes.map((code: ICode) => {
    if (code.type === CODE_CATEGORY_DIAGNOSIS_OLD) {
      return {
        ...code,
        type: CODE_CATEGORY_DIAGNOSIS,
      };
    }
    if (CODE_CATEGORIES_OLD_PROCEDURE.includes(code.type)) {
      return {
        ...code,
        type: CODE_CATEGORY_PROCEDURE,
      };
    }
    return code;
  });
};

/**
 * Merge two array of codes, taking into account modifiers.
 *
 * @param codes1
 * @param codes2
 * @returns
 */
export const mergeCodes = (codes1: ICode[], codes2: ICode[]) => {
  const resultCodes = [];

  codes1.forEach((code: ICode) => {
    if (!doesIncludeCode(resultCodes, code)) {
      resultCodes.push(code);
    }
  });

  codes2.forEach((code: ICode) => {
    if (!doesIncludeCode(resultCodes, code)) {
      resultCodes.push(code);
    }
  });

  return resultCodes;
};

/**
 * Compare two codes taking into account their modifiers.
 *
 * @param code1
 * @param code2
 * @returns
 */
const compareCodes = (code1: ICode, code2: ICode) => {
  return (
    code1.name === code2.name &&
    codeNameWithSortedModifiers(code1) === codeNameWithSortedModifiers(code2)
  );
};

/**
 * Check whether the array of codes includes the given code.
 *
 * @param codes
 * @param searchedCode
 * @returns
 */
const doesIncludeCode = (codes: ICode[], searchedCode: ICode) => {
  return codes.some((code: ICode) => compareCodes(code, searchedCode));
};

/**
 * Add only those codes which are not duplicates.
 *
 * @param existingCodes
 * @param supposedlyNewCodes
 * @returns
 */
export const addNewCodes = (existingCodes: ICode[], supposedlyNewCodes: ICode[]) => {
  // If there are no existing codes, we assume that the case has just been received from EHR or been opened.
  // If so, we shouldn't filter out duplicate codes.
  if (existingCodes.length === 0) {
    return supposedlyNewCodes;
  }

  const result = existingCodes;

  supposedlyNewCodes.forEach((supposedlyNewCode: ICode) => {
    if (!doesIncludeCode(result, supposedlyNewCode)) {
      result.push(supposedlyNewCode);
    }
  });

  return result;
};
