import { PATH_INBOX, PATH_INBOX_COMPLETED } from '@/config/paths';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Button,
  Box,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useUserPreferences from '@/hooks/useUserPreferences';
import { isAlphaSecretary } from '@/services/featureFlag';
import useUser from '@/hooks/useUser';

const InboxTabs = () => {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const userPreferences = useUserPreferences();

  const handleChange = (_: React.MouseEvent<HTMLElement>, newTab: string) => {
    navigate(newTab);
  };

  const shouldDisplayDraftSelector = isAlphaSecretary() && location.pathname === PATH_INBOX;

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      {shouldDisplayDraftSelector && (
        <Button
          variant="outlined"
          onClick={() => userPreferences.setShowDrafts(!userPreferences.showDrafts)}
          className="dd-privacy-allow"
          startIcon={
            <DescriptionIcon color={userPreferences.showDrafts ? 'secondary' : 'primary'} />
          }
          sx={{
            background: 'white',
          }}
        >
          <Typography variant="button" color="black">
            {userPreferences.showDrafts ? 'Skjul kladder' : 'Vis kladder'}
          </Typography>
        </Button>
      )}

      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          color="primary"
          value={location.pathname}
          exclusive
          onChange={handleChange}
          aria-label="Cases"
        >
          <ToggleButton value={PATH_INBOX} className="dd-privacy-allow">
            <EmojiFlagsIcon
              color={location.pathname === PATH_INBOX ? 'secondary' : 'primary'}
              sx={{ mr: 1 }}
            />{' '}
            Markerede
          </ToggleButton>
          <ToggleButton value={PATH_INBOX_COMPLETED} className="dd-privacy-allow">
            <CheckCircleOutlineIcon
              color={location.pathname === PATH_INBOX_COMPLETED ? 'secondary' : 'primary'}
              sx={{ mr: 1 }}
            />{' '}
            Indsendte
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </Box>
  );
};

export default InboxTabs;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: 0,
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.6),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    border: 0,
    '&.MuiToggleButton-root': {
      fontSize: '13px',
      fontWeight: 600,
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
      color: theme.palette.getContrastText('#ffffff'),
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
