import { CAPIO_CUSTOMER_NAME, CAPIO_FLAGGED_NOTE_PREFIX } from '@/config/constants';
import { ICaseNoteStructured } from '@/types/ICaseNoteStructured';
import { distance } from 'fastest-levenshtein';

const DEFAULT_NOTE_STRUCTURE_KEY = 'note';

export const getDefaultNoteStructure = (note?: string) => {
  return { [DEFAULT_NOTE_STRUCTURE_KEY]: note || '' };
};

export const isNoteStructureEmpty = (note: ICaseNoteStructured | null): boolean => {
  // If note is null
  if (note == null) {
    return true;
  }

  // if the number of note fields is greater than 1.
  if (Object.keys(note).length > 1) {
    return false;
  }

  // If the note field is an empty string, we return true.
  return note?.[DEFAULT_NOTE_STRUCTURE_KEY] === '';
};

export const structuredNoteToNonJsonString = (note: ICaseNoteStructured) => {
  if (note == null) {
    return '';
  }

  const keys = Object.keys(note);

  // For the default structure (no structure), we only send not content itself.
  if (keys.length === 1 && keys[0] === DEFAULT_NOTE_STRUCTURE_KEY) {
    return note[keys[0]];
  }

  return keys.map((key: string) => key + '\n\n' + note[key]).join('\n\n');
};

/**
 * Customize the note before submitting to EHR.
 *
 * @param note
 * @param isFlagged
 * @param customer
 * @returns
 */
export const customizeNoteIfNecessary = (note: string, isFlagged: boolean, customer: string) => {
  if (!isFlagged || customer !== CAPIO_CUSTOMER_NAME) {
    return note;
  }

  // We only prefix the flagged note for the Capio customer.
  return CAPIO_FLAGGED_NOTE_PREFIX + '\n\n' + note;
};

export const appendNoteStructured = (
  noteStructuredBase: ICaseNoteStructured,
  noteStructuredSegment: ICaseNoteStructured,
): ICaseNoteStructured => {
  const segmentKeys = Object.keys(noteStructuredSegment);

  const resultNote = { ...noteStructuredBase };

  segmentKeys.forEach((key: string) => {
    resultNote[key] = concatenateSegmentStrings(resultNote[key], noteStructuredSegment[key]);
  });

  return resultNote;
};

export const appendNoteCorrected = (
  noteCorrectedBase: string,
  noteCorrectedSegment: string,
): string => {
  return concatenateSegmentStrings(noteCorrectedBase, noteCorrectedSegment);
};

export const concatenateSegmentStrings = (
  segment1: string | undefined,
  segment2: string | undefined,
): string => {
  if (segment1 == null || segment2 == null) {
    return segment1 || segment2;
  }

  const segment2Updated = removeLeadingDotFromSecondSegment(segment1, segment2);

  const connector = determineSegmentConnector(segment1, segment2Updated);

  return [segment1, segment2Updated].filter((x) => x).join(connector);
};

/**
 * Decide on whether a blank space is needed between two connected segments.
 *
 * @param segment1
 * @param segment2
 * @returns
 */
const determineSegmentConnector = (segment1: string, segment2: string) => {
  // 1. If the last character of Journalnotat = \s, then do not add a whitespace.
  if (segment1.match(/\s$/)) {
    return '';
  }

  // 2. If the first character of the incoming segment = regex [,-/.:\s], then do not add a whitespace.
  if (segment2.match(/^[\,\.\:\s]/)) {
    return '';
  }

  return ' ';
};

/**
 * If the first segment string ends with a dot and the second segment string starts with a dot,
 * it removes the dot from the start of the second segment and returns it.
 *
 * @param segment1
 * @param segment2
 * @returns {string} the second segment
 */
const removeLeadingDotFromSecondSegment = (segment1: string, segment2: string): string => {
  if (!segment1.endsWith('.') || !segment2.startsWith('.')) {
    return segment2;
  }

  return segment2.replace(/^\./, '');
};

const normalizeString = (string: string): string => {
  string = string.replace(/&#.*?;/g, ' ');
  string = string.replace(/[\n\t]/g, ' ').replace(/\s+/g, ' ');
  string = string.replace(/[*_]/g, '');
  string = string.trim();
  string = string.toLocaleLowerCase();
  return string;
};

const calculateCER = (reference: string, hypothesis: string) => {
  const levenshteinDistance = distance(reference, hypothesis);
  return levenshteinDistance / reference.length;
};

export const calculateEditMetrics = (reference: string, hypothesis: string) => {
  const normalizedReference = normalizeString(reference);
  const normalizedHypothesis = normalizeString(hypothesis);
  const cer = calculateCER(normalizedReference, normalizedHypothesis);
  const editDistance = distance(normalizedReference, normalizedHypothesis);
  return {
    originalNoteLength: hypothesis.length,
    submittedNoteLength: reference.length,
    characterErrorRate: cer,
    editDistance,
  };
};
