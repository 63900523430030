import Dexie, { Table } from 'dexie';
import { type CodeSystemModelType, CodeSystemTables } from './codeSystem';

export class CortiCodeDexie extends Dexie {
  codeSystems!: Table<CodeSystemModelType>;

  constructor() {
    super('corti-code');
    this.version(1).stores({
      ...CodeSystemTables,
    });
  }
}

export const db = new CortiCodeDexie();

export { type CodeSystemModelType };
