import { PATH_INBOX, PATH_INBOX_COMPLETED, PATH_NEW_CASE_FROM_XM } from '@/config/paths';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCases from './useCases';
import useUserPreferences from './useUserPreferences';

function useForceRedirects() {
  const cases = useCases();
  const location = useLocation();
  const navigate = useNavigate();
  const userPreferences = useUserPreferences();

  /// We use RootPage component for force redirects (bypassing all other conditions/restrictions)
  /// because it's higher in the order.

  // Used to redirect user to the /newehr page when a new request comes from XM.
  useEffect(() => {
    if (cases.forceRedirectToNewEhrPage && location.pathname !== PATH_NEW_CASE_FROM_XM) {
      navigate(PATH_NEW_CASE_FROM_XM);
      cases.disableForceRedirectToNewEhr();
    }
  }, [location.pathname, cases.forceRedirectToNewEhrPage]);

  // Used to redirect user to Inbox if the forceRedirectToInbox flag is set.
  useEffect(() => {
    // Force redirect to inbox if the forceRedirect flag is set and if it's not an inbox page already.
    if (
      cases.forceRedirectToInbox &&
      ![PATH_INBOX, PATH_INBOX_COMPLETED].includes(location.pathname)
    ) {
      navigate(userPreferences.lastInboxPath);
      cases.disableForceRedirectToInbox();
    }
  }, [location.pathname, cases.forceRedirectToInbox]);
}

export default useForceRedirects;
