import { CODE_CATEGORY_DIAGNOSIS } from '@/config/constants';
import { ICode } from '@/types/ICode';
import {
  getCodeNameWithModifiers,
  getCodeWithModifiersDescription,
  getNewModifiers,
} from '@/utils/misc';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  SxProps,
  Theme,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { CodeModifierSelector } from './CodeModifierSelector';

export enum CodeType {
  Diagnosis,
  Treatment,
  Operation,
}

interface ICodeCardProps {
  code: ICode;
  edit: boolean;
  addHandler?: () => void;
  removeHandler?: () => void;
  areModifiersEditable?: boolean;
  index?: number;
  sx?: SxProps<Theme>;
}

export const CodeCard: FC<ICodeCardProps> = observer((props) => {
  const { code, edit, addHandler, removeHandler, areModifiersEditable = false, index, sx } = props;

  const normalizedModifiers = getNewModifiers(code);

  const codeName = code.name;
  const codeNameWithModifiers = getCodeNameWithModifiers(codeName, normalizedModifiers);
  const codeDescription = getCodeWithModifiersDescription(code.description, normalizedModifiers);

  const isPrimaryDiagnosis = code.type === CODE_CATEGORY_DIAGNOSIS && index === 0;

  // localize code type
  const danishCodeType = code.type === 'diagnosis' ? 'Diagnose' : code.type;

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        borderColor: isPrimaryDiagnosis ? blue[200] : null,
        background: isPrimaryDiagnosis
          ? blue[50]
          : 'linear-gradient(112deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.6) 100%)',
        boxShadow: 'rgba(186, 142, 255, 0.08) 0px 2px 8px -1px',
        ...(sx as any),
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <StyledCardContent
          sx={{
            flex: 1,
            p: 1.5,
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-start',
                minWidth: '70px',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                component="div"
                variant="body1"
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  letterSpacing: '0.15px',
                  flex: 'none',
                  mr: 1,
                }}
              >
                {edit && areModifiersEditable ? codeName : codeNameWithModifiers}
              </Typography>
              <Chip
                size="small"
                label={(isPrimaryDiagnosis ? 'Primær ' : '') + danishCodeType}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 400,
                  fontSize: '10px',
                  width: 'auto',
                  py: '1px',
                  mt: '2px',
                  height: 'auto',
                  maxWidth: '50%',
                  minWidth: '50px',
                  '.MuiChip-label': {
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  },
                }}
              />
            </Box>

            <Typography
              component="div"
              variant="caption"
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '18px',
                color: grey[800],
                letterSpacing: '0.15px',
                wordBreak: 'break-all',
              }}
            >
              {codeDescription}
            </Typography>
            {edit && areModifiersEditable && (
              <CodeModifierSelector code={code} defaultModifiers={normalizedModifiers} />
            )}
          </Box>
        </StyledCardContent>
        {(addHandler || removeHandler) && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: isPrimaryDiagnosis ? blue[200] : null,
            }}
          />
        )}
        {edit && removeHandler && (
          <StyledButton
            sx={{
              '&:hover, &:focus': {
                backgroundColor: red[50],
              },
            }}
            onClick={removeHandler}
            aria-label="delete"
          >
            <DeleteForeverIcon color="warning" />
          </StyledButton>
        )}
        {edit && addHandler && (
          <StyledButton
            sx={{
              backgroundColor: 'hsl(152 49% 95% / 1)',
              '&:hover, &:focus': {
                backgroundColor: 'hsl(152 49% 88% / 1)',
              },
            }}
            onClick={addHandler}
            aria-label="add"
          >
            <AddCircleIcon color="success" />
          </StyledButton>
        )}
      </Box>
    </Card>
  );
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 0,
  paddingRight: 0,
  '&:last-child': {
    paddingBottom: 10,
  },
});

const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  width: '48px',
  minWidth: 'unset',
  borderRadius: 0,
  opacity: 0.8,
  transition: 'all 200ms',
  '&:hover, &:focus': {
    opacity: 1,
  },
});
