import useAlerts from '@/hooks/useAlerts';
import useCases from '@/hooks/useCases';
import {
  DEFAULT_DEVICE_NAME,
  getAudioDevices,
  getSelectedDevice,
  reportVolumeLevel,
} from '@/utils/audio';
import { StarBorder } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import MicIcon from '@mui/icons-material/Mic';
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  LinearProgress,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';

interface IProps {}
const AudioRecordingSourceControl: FC<IProps> = observer(() => {
  const [isSettingsPanelVisible, setIsSettingsPanelVisible] = useState<boolean>(false);
  // The default capture device will be listed first.
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [currentDevice, setCurrentDevice] = useState<MediaDeviceInfo>();
  const isJabraDevice = currentDevice?.label.toLowerCase().includes('jabra');
  const cases = useCases();
  const alerts = useAlerts();

  const openSettings = () => {
    if (devices.length === 0) {
      alerts.error(
        'Please make sure your microphone has been plugged in and you allowed access to it.',
      );
      return;
    }
    setIsSettingsPanelVisible(true);
  };
  const closeSettings = () => setIsSettingsPanelVisible(false);

  // on device list change, update the current devices

  useEffect(() => {
    getAudioDevices()
      .then((systemDevices: MediaDeviceInfo[]) => {
        setDevices(systemDevices);

        if (systemDevices.length === 0) {
          alerts.error('No active microphone found');
          console.error('No active audio input devices found');
          return;
        }
      })
      .catch((e) => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
        });

        alerts.error(
          'Please make sure your microphone has been plugged in and you allowed access to it.',
        );
        console.error(e);
      });
  }, []);

  navigator.mediaDevices.addEventListener('devicechange', () => {
    getAudioDevices()
      .then((systemDevices: MediaDeviceInfo[]) => {
        setDevices(systemDevices);
      })
      .catch((e) => {
        alerts.error(
          'Please make sure your microphone has been plugged in and you allowed access to it.',
        );
        console.error(e);
      });
  });

  useEffect(() => {
    const device = getSelectedDevice(devices, cases.audioRecordingDevice);
    setCurrentDevice(device);
    cases.setAudioRecordingDevice(device);
  }, [devices, cases.audioRecordingDevice]);

  return (
    <Tooltip
      arrow={false}
      open={isSettingsPanelVisible}
      onClose={closeSettings}
      onOpen={openSettings}
      disableFocusListener={true}
      disableHoverListener={true}
      placement="top-start"
      title={<AudioRecordingDeviceSelector onClose={closeSettings} devices={devices} />}
    >
      <Button
        color="neutral"
        onClick={isSettingsPanelVisible ? closeSettings : openSettings}
        className="dd-privacy-allow"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          color: grey[700],
          textTransform: 'unset',
          width: '240px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: '0.8rem',
          fontWeight: 600,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '100px',
          gap: 1,
          borderColor: isJabraDevice ? green[400] : red[600],
        }}
      >
        <Tooltip
          title={
            isJabraDevice
              ? 'Du bruger den bedste mikrofon til diktering. Sørg for at bære Jabra-headsettet for optimal lydkvalitet.'
              : 'Den nuværende mikrofon er ikke optimal til diktering. Skift venligst til Jabra headset.'
          }
        >
          <Chip
            color={isJabraDevice ? 'success' : 'error'}
            label={isJabraDevice ? 'Jabra' : 'Ikke-Jabra'}
            variant="outlined"
            sx={{
              textTransform: 'uppercase',
            }}
          />
        </Tooltip>
        {currentDevice?.label.replace('Default - ', '') || DEFAULT_DEVICE_NAME}
      </Button>
    </Tooltip>
  );
});

export default AudioRecordingSourceControl;

interface IAudioRecordingDeviceSelector {
  devices: MediaDeviceInfo[];
  onClose: () => void;
}
const AudioRecordingDeviceSelector: FC<IAudioRecordingDeviceSelector> = observer(
  ({ onClose, devices }) => {
    const cases = useCases();
    const [volume, setVolume] = useState<number>(0);
    const [currentDeviceId, setCurrentDeviceId] = useState<string>();

    const stream = useRef<MediaStream | null>(null);

    const handleOnClose = () => {
      onClose();
      console.log('handleOnClose', stream);
      stream?.current?.getTracks().forEach((track) => track.stop());
    };

    const handleChange = (device: MediaDeviceInfo) => {
      cases.setAudioRecordingDevice(device);
      handleOnClose();
      // save the selected device to the local storage
      localStorage.setItem('audioRecordingDevice', device.deviceId);
    };

    useEffect(() => {
      const getDeviceID = async () => {
        const device = getSelectedDevice(devices, cases.audioRecordingDevice);
        if (device) {
          setCurrentDeviceId(device.deviceId);
          stream.current = await reportVolumeLevel(setVolume, device.deviceId);
        }
      };
      getDeviceID();

      return () => {
        setVolume(0);
        stream?.current?.getTracks().forEach((track) => track.stop());
      };
    }, [devices, cases.audioRecordingDevice]);

    const isJabraDevice = (device: MediaDeviceInfo) => device.label.toLowerCase().includes('jabra');

    return (
      <ClickAwayListener onClickAway={handleOnClose}>
        <Box sx={{ my: 1, minWidth: '190px' }}>
          {devices.map((device: MediaDeviceInfo) => (
            <MenuItem
              sx={{
                px: 1,
                fontSize: '14px',
                color:
                  device.deviceId === currentDeviceId
                    ? isJabraDevice(device)
                      ? green[200]
                      : red[200]
                    : 'white',
                textWrap: 'wrap',
              }}
              key={device.deviceId}
              value={device.deviceId}
              onClick={() => handleChange(device)}
            >
              {isJabraDevice(device) ? (
                <StarBorder sx={{ width: '16px', mr: '6px' }} color="inherit" />
              ) : device.deviceId === currentDeviceId ? (
                <CheckIcon sx={{ width: '16px', mr: '6px' }} color="inherit" />
              ) : (
                <Box sx={{ width: '16px', mr: '6px' }}></Box>
              )}
              {device?.label || DEFAULT_DEVICE_NAME}
            </MenuItem>
          ))}

          <Box
            sx={{
              pt: 1,
              px: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <MicIcon />
            <LinearProgress sx={{ width: '100%' }} variant="determinate" value={volume} />
          </Box>
        </Box>
      </ClickAwayListener>
    );
  },
);
