import { IAudioRegion } from '@/types/IAudioRegion';
import { concat, subbuffer } from 'audio-buffer-utils';
import toWav from 'audiobuffer-to-wav';

/**
 * Return an AudioBuffer that has all silent fragments removed.
 *
 * @param audioBlob
 */
export const removeSilenceFromAudioData = async (audioBlob: Blob) => {
  const audioContext = new AudioContext();
  const arrayBuffer = await audioBlob.arrayBuffer();
  const inputAudioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const output = await silenceRemovalAlgorithm(inputAudioBuffer);

  const blob = new Blob([toWav(output)], {
    type: 'audio/wav',
  });

  return blob;
};

const silenceRemovalAlgorithm = async (audioBuffer: AudioBuffer) => {
  // Extract non-silent regions.
  const regions = extractRegions(audioBuffer);

  // @todo: Replace subbuffer() and concat() from 'audio-buffer-utils' with their browser alternatives
  // to avoid including node modules into the bundle.

  // Convert the extracted regions to buffers.
  const buffers = regions.map(({ start, end }) => {
    return subbuffer(audioBuffer, start, end);
  });

  // Concat the non-silent buffers.
  return concat(buffers);
};

/**
 * Find regions separated by silence.
 *
 * The code borrowed from examples of wavesurfer.js and adapted to our needs.
 * Source: https://wavesurfer-js.org/examples/#silence.js
 * License: BSD-3-Clause License
 * Original author: katspaugh (https://github.com/katspaugh)
 */
const extractRegions = (audioBuffer: AudioBuffer) => {
  const audioData = audioBuffer.getChannelData(0);
  const duration = audioBuffer.duration;

  const minValue = 0.01;
  const minSilenceDuration = 1.5; // in seconds.
  const mergeDuration = 0.2;
  const scale = duration / audioData.length;
  const silentRegions: IAudioRegion[] = [];

  // Find all silent regions longer than minSilenceDuration.
  let start = 0;
  let end = 0;
  let isSilent = false;
  for (let i = 0; i < audioData.length; i++) {
    if (audioData[i] < minValue) {
      if (!isSilent) {
        start = i;
        isSilent = true;
      }
    } else if (isSilent) {
      end = i;
      isSilent = false;
      if (scale * (end - start) > minSilenceDuration) {
        silentRegions.push({
          start,
          end,
        });
      }
    }
  }

  // If the recently opened region is not yet taken into account, do it.
  if (end < start && isSilent) {
    silentRegions.push({
      start,
      end: audioData.length - 1,
    });
  }

  // Merge silent regions that are close together.
  const mergedSilentRegions: IAudioRegion[] = [];
  let lastRegion = null;
  for (let i = 0; i < silentRegions.length; i++) {
    if (
      lastRegion &&
      silentRegions[i].start * scale - lastRegion.endIndex * scale < mergeDuration
    ) {
      lastRegion.end = silentRegions[i].end;
    } else {
      lastRegion = silentRegions[i];
      mergedSilentRegions.push(lastRegion);
    }
  }

  // Find regions that are not silent.
  const regions: IAudioRegion[] = [];
  let lastEnd = 0;
  for (let i = 0; i < mergedSilentRegions.length; i++) {
    regions.push({
      start: lastEnd,
      end: mergedSilentRegions[i].start,
    });
    lastEnd = mergedSilentRegions[i].end;
  }

  // If the last region of the audio is not silent...
  if (lastEnd < audioData.length - 1) {
    regions.push({
      start: lastEnd,
      end: audioData.length - 1,
    });
  }

  return regions;
};
