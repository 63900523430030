import { EXMCaseStatusType } from '@/types/EXMCaseStatusType';
import { ICode } from '@/types/ICode';
import { IElectronAxiosClient } from '@/types/ICortiCodeAPI';
import { ICustomerConfig } from '@/types/ICustomerConfig';
import { isDevelopment, isTest } from '@/utils/env';
import { getOldModifiers } from '@/utils/misc';
import {
  IAuthResponse,
  ICodeType,
  ICreateOrUpdateNotePayload,
  ICreateOrUpdateNoteResponse,
  IXMedicusConfig,
} from './types';

function _getAuth(user, pass) {
  return {
    username: user,
    password: pass,
  };
}

function convertCodes(codes: ICode[]): ICodeType[] {
  return codes.map((code: ICode, _) => {
    return {
      Code: code.name,
      AdditionalCodes: getOldModifiers(code).map((modifier) => modifier),
    };
  });
}

// Cache the ss-id
function getAuthenticateFn() {
  const cache: any = {};

  return async (
    client: IElectronAxiosClient,
    xmedicus_config: IXMedicusConfig,
    forceReAuth?: Boolean,
  ) => {
    if (cache.ssid == undefined || forceReAuth) {
      const params = {
        username: xmedicus_config.ws_username,
        password: xmedicus_config.ws_password,
      };

      const response: IAuthResponse = await client.post('https://ws.capio.dk/auth/bysystem', null, {
        params: params,
        auth: _getAuth(xmedicus_config.username, xmedicus_config.password),
        withCredentials: true,
      });

      cache.ssid = response.SessionId;
    }

    return cache.ssid;
  };
}
const authenticate = getAuthenticateFn();

const statusFlagMap: { [key in EXMCaseStatusType] } = {
  [EXMCaseStatusType.NONE]: 'none',
  [EXMCaseStatusType.NORMAL]: 'normal',
  [EXMCaseStatusType.DRAFT]: 'draft',
  [EXMCaseStatusType.ACUTE]: 'acute',
  [EXMCaseStatusType.UNCLEAR]: 'unclear',
};

export async function submit(
  config: ICustomerConfig,
  externalId: string,
  note: string,
  patientId: string,
  doctorId: string,
  codes: ICode[],
  statusFlag: EXMCaseStatusType,
  noteId?: string,
) {
  const xmedicus_config: IXMedicusConfig = config.additional_config;

  const params = {
    format: 'json',
  };

  const payload: ICreateOrUpdateNotePayload = {
    FocusId: externalId,
    Text: note,
    PersonId: patientId,
    DoctorId: doctorId,
    TextFormat: 1, // HTML formatted (0 is raw)
    Codes: convertCodes(codes),
    NoteId: noteId,
    StatusFlag: statusFlagMap[statusFlag],
  };

  console.log(payload);

  if (isDevelopment() || isTest()) {
    return;
  }

  // Authenticate and get session cookie
  const r = await authenticateAndPostWithRetry(xmedicus_config, payload, params);

  console.log(r);
  return r.Id;
}

/**
 * Use a retry logic to handle network errors and keep retrying for a maximum of 3 times.
 * @param xmedicus_config: IXMedicusConfig
 * @param payload: ICreateOrUpdateNotePayload
 * @param params
 * @returns
 */
async function authenticateAndPostWithRetry(
  xmedicus_config,
  payload,
  params,
): Promise<ICreateOrUpdateNoteResponse> {
  const maxRetries = 3;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const session_id = await authenticate(
        window.cortiCodeAPI.electronAxiosClient,
        xmedicus_config,
        retries > 0,
      );

      const r: ICreateOrUpdateNoteResponse = await window.cortiCodeAPI.electronAxiosClient.post(
        'https://ws.capio.dk/CreateOrUpdateNote',
        payload,
        {
          params: params,
          auth: _getAuth(xmedicus_config.username, xmedicus_config.password),
          withCredentials: true,
          headers: { Cookie: `ss-id=${session_id}` },
        },
      );

      return r;
    } catch (error) {
      console.error(`Request failed. Retrying (${retries + 1}/${maxRetries})...`, error);
      retries++;
      await new Promise((resolve) => setTimeout(resolve, 1500 * retries));
    }
  }

  throw new Error('Max retries exceeded. Request failed. Could not - "CreateOrUpdateNote"');
}
